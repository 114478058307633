import React from "react";
import ReactDOM from "react-dom/client";
import "src/scss/main.css";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import { createCustomTheme } from "src/Themes/index";
import { WagmiConfig, createConfig, mainnet } from "wagmi";
import "react-toastify/dist/ReactToastify.css";
import { createPublicClient, http } from "viem";

const theme = createCustomTheme();
const root = ReactDOM.createRoot(document.getElementById("root"));
const config = createConfig({
  autoConnect: true,
  publicClient: createPublicClient({
    chain: mainnet,
    transport: http(),
  }),
});
root.render(
  <ThemeProvider theme={theme}>
    <WagmiConfig config={config}>
      <ToastContainer position="top-right" theme="dark" />
      <App />
    </WagmiConfig>
  </ThemeProvider>
);
