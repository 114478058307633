import BackButton from "src/Components/commonbackbutton/BackButton";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTransaction } from "wagmi";
import axios from "axios";
import SeekTokenDetail from "./SeekTokenDetail";
import DiveTransactionDetail from "./DiveTransactionDetail";
import DiscoverToken from "./DiscoverToken";
import { apiRouterCall } from "src/config/service";

import ApiConfig from "src/config/APICongig";
import { toast } from "react-toastify";
import UnveilTokenDetail from "./UnveilTokenDetail";

export default function TransactionDive() {
  const location = useLocation();
  const [type, setType] = useState("");
  const [address, setAddress] = useState("");

  const { data, isError, isLoading } = useTransaction({ hash: address });
  const [expanded, setExpanded] = useState("panel1");
  const [seekTokenEssenceData, setSeekTokenEssenceData] = useState(null);
  const [discoverDetail, setDiscoverDetail] = useState(null);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [newsData, setNewsData] = useState("");
  const [unveilDetail, setUnveilDetail] = useState("");
  const [checkingData, setCheckingData] = useState(true);

  const [marketValue, setMarketValue] = useState({
    percentage: "",
    marketCapChange: "",
    changeVolume: "",
    totalVolume: "",
    price: "",
    marketCap: "",
  });

  useEffect(() => {
    const dataType = location?.state?.type;
    if (dataType) {
      setType(dataType);
    }
  }, [location]);
  const fetchNewsData = async () => {
    setIsLoading2(true);

    try {
      const res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.fetchNewsAndStore,
      });

      console.log("res-hgghh-=-=", res);

      if (res.data.statusCode === 200) {
        const newsData = res.data.responseResult;
        setNewsData(newsData);
        setIsLoading2(false);
      } else {
        setIsLoading2(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading2(false);
    }
  };

  useEffect(() => {
    if (type === "news") {
      fetchNewsData();
    }
  }, [type]);
  const unveilTokenHandler = async () => {
    setIsLoading2(true);

    try {
      const res = await apiRouterCall({
        method: "GET",
        url: ApiConfig.unveilTokenPower,
        paramsData: {
          address: address,
        },
      });
      console.log("unveilTokenPower-hgghh-=-=", res);
      if (res.data.statusCode === 200) {
        setIsLoading2(false);
        setCheckingData(true);
        // toast.success(res.data.responseMessage);
        setUnveilDetail(res.data.responseResult);
      } else {
        setIsLoading2(false);
        toast.error(res.data.responseMessage);
        setUnveilDetail("");
        setCheckingData(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading2(false);
      setUnveilDetail("");
      setCheckingData(false);
    }
  };

  const tokenList = async () => {
    try {
      setIsLoading1(true);
      const resp = await axios.get(
        `https://api.coingecko.com/api/v3/coins/list`
      );
      const tokenname = address.trim().toLowerCase();
      const tokenData = resp.data.find(
        (token) => token.name.toLowerCase().trim() === tokenname
      );

      if (!tokenData) {
        alert("Not found");
        setIsLoading1(false);
        return;
      }

      const currentDate = new Date();
      const year =
        currentDate.getFullYear() > 10
          ? currentDate.getFullYear()
          : `0${currentDate.getFullYear()}`;
      const month =
        currentDate.getMonth() + 1 > 10
          ? currentDate.getMonth() + 1
          : `0${currentDate.getMonth() + 1}`;
      const day =
        currentDate.getDate() > 10
          ? currentDate.getDate()
          : `0${currentDate.getDate()}`;

      const response = await axios.get(
        `https://api.coingecko.com/api/v3/coins/${tokenData.id}/history?date=${day}-${month}-${year}`
      );
      setSeekTokenEssenceData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading1(false);
    }
  };

  const DiscoverTokenList = async () => {
    try {
      setIsLoading1(true);
      let resp = await axios.get(`https://api.coingecko.com/api/v3/coins/list`);
      let tokenName = "no";

      const tokenID = address.toString().trim().toLowerCase();

      let flag = false;
      for (let i = 0; i < resp.data.length; i++) {
        if (
          resp.data[i]["symbol"].trim().toLowerCase().toString() === tokenID
        ) {
          tokenName = resp.data[i]["id"];
          flag = true;
          break;
        }
      }
      if (flag === false) {
        alert("Not found");
        setIsLoading1(false);

        return;
      }
      resp = await axios.get(
        `https://api.coingecko.com/api/v3/coins/${tokenName}/market_chart?vs_currency=usd&days=1&precision=12`
      );

      console.log("tokendetaillll", resp.data);
      if (
        !Object.keys(resp["data"]).includes("prices") ||
        !Object.keys(resp["data"]).includes("market_caps") ||
        !Object.keys(resp["data"]).includes("total_volumes")
      ) {
        alert("Not found");
        return;
      }
      const pricesArrayLength = resp["data"]["prices"].length;
      const marketCapsArrayLenght = resp["data"]["market_caps"].length;
      const totalVolumesArrayLength = resp["data"]["total_volumes"].length;
      console.log(
        resp["data"]["total_volumes"][totalVolumesArrayLength - 1][1],
        resp["data"]["total_volumes"][totalVolumesArrayLength - 1]
      );
      const percentageChange =
        ((resp["data"]["prices"][0][1] -
          resp["data"]["prices"][pricesArrayLength - 1][1]) /
          resp["data"]["prices"][pricesArrayLength - 1][1]) *
        100;
      const percentageChangeInMarketCaps =
        ((resp["data"]["market_caps"][0][0] -
          resp["data"]["market_caps"][marketCapsArrayLenght - 1][0]) /
          Math.abs(resp["data"]["market_caps"][marketCapsArrayLenght - 1][0])) *
        100;
      const percentageChangeInTotalVolumes =
        ((resp["data"]["total_volumes"][0][1] -
          resp["data"]["total_volumes"][totalVolumesArrayLength - 1][1]) /
          Math.abs(
            resp["data"]["total_volumes"][totalVolumesArrayLength - 1][1]
          )) *
        100;
      setMarketValue({
        percentage: percentageChange,
        marketCapChange: percentageChangeInMarketCaps,
        changeVolume: percentageChangeInTotalVolumes,
        totalVolume: resp["data"]["total_volumes"][1],
        price: resp["data"]["prices"][0][1],
        marketCap: resp["data"]["market_caps"][1],
      });
      setDiscoverDetail(resp.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading1(false);
    }
  };

  const fetchImage = async (url) => {
    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);
      return objectURL;
    } catch (error) {
      console.error("Error fetching image:", error);
      throw error;
    }
  };

  return (
    <Box>
      <BackButton
        title={
          type === "dive"
            ? "Dive into Transactions"
            : type === "seek"
            ? "Seek Token Essence"
            : type === "unveil"
            ? "Unveil Token Power"
            : type === "news"
            ? "Latest News"
            : "Discover Token Knowledge"
        }
      />
      <Container>
        {type !== "news" && (
          <Box className="card_common border_radius_10" p={1}>
            <TextField
              placeholder={
                type === "dive"
                  ? "Please enter the transaction hash of the contract."
                  : type === "seek"
                  ? "Please enter the name of the token."
                  : type === "unveil"
                  ? "Please enter your token address."
                  : "Please enter the token symbol."
              }
              value={address}
              type="text"
              variant="outlined"
              fullWidth
              onChange={(e) => setAddress(e.target.value)}
            />

            {type !== "dive" && (
              <Box mt={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (type === "discover") {
                      DiscoverTokenList();
                    }
                    if (type === "seek") {
                      tokenList();
                    }
                    if (type === "unveil") {
                      unveilTokenHandler();
                    }
                  }}
                  disabled={isLoading1}
                >
                  Submit
                </Button>
              </Box>
            )}
          </Box>
        )}
        <Box mt={3}>
          {isLoading && type === "dive" ? (
            <Box display="flex" justifyContent="center" mt={1} mb={1}>
              <CircularProgress />
            </Box>
          ) : (
            data && (
              <Box>
                <DiveTransactionDetail
                  data={data}
                  expanded={expanded}
                  setExpanded={setExpanded}
                />
              </Box>
            )
          )}
        </Box>
        <Box mt={3}>
          {isLoading1 && type === "discover" ? (
            <Box display="flex" justifyContent="center" mt={1} mb={1}>
              <CircularProgress />
            </Box>
          ) : (
            discoverDetail && (
              <Box>
                <DiscoverToken marketValue={marketValue} />
              </Box>
            )
          )}
        </Box>
        <Box mt={3}>
          {isLoading2 && type === "unveil" ? (
            <Box display="flex" justifyContent="center" mt={1} mb={1}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {!checkingData && (
                <Box mt={2}>
                  <Typography variant="h6" textAlign="center">
                    Data Not Found
                  </Typography>
                </Box>
              )}
              {unveilDetail && checkingData && (
                <>
                  <Box>
                    <UnveilTokenDetail unveilDetail={unveilDetail} />
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
        <Box mt={3}>
          {isLoading1 && type === "seek" ? (
            <Box display="flex" justifyContent="center" mt={1} mb={1}>
              <CircularProgress />
            </Box>
          ) : (
            type === "seek" &&
            seekTokenEssenceData && (
              <Box mt={2}>
                <Typography
                  variant="h6"
                  style={{ color: "#fff", textAlign: "center" }}
                >
                  Seek Token Essence Details
                </Typography>
                <Box mt={3}>
                  {seekTokenEssenceData && (
                    <SeekTokenDetail
                      seekTokenEssenceData={seekTokenEssenceData}
                    />
                  )}
                </Box>
              </Box>
            )
          )}
        </Box>
        {type === "news" && (
          <Box mt={3}>
            {isLoading2 && (
              <Box display="flex" justifyContent="center" mt={1} mb={1}>
                <CircularProgress />
              </Box>
            )}
            {!isLoading2 && newsData.length > 0 && (
              <Box>
                <Box mt={2}>
                  {newsData &&
                    newsData.map((newsItem) => (
                      <Box key={newsItem._id} mb={2} className="card_common">
                        <Box p={2}>
                          {/* <Box>
                            {newsItem.url && (
                              <img
                                src={fetchImage(newsItem.url)}
                                alt=""
                                style={{
                                  width: "100%",
                                  maxHeight: "150px",
                                  objectFit: "cover",
                                }}
                              />
                            )}
                          </Box> */}
                          <Typography
                            variant="subtitle1"
                            style={{ color: "#fff" }}
                          >
                            {newsItem.title}
                          </Typography>
                          <Typography
                            variant="caption"
                            style={{ color: "#aaa" }}
                          >
                            Published on{" "}
                            {new Date(newsItem.published_at).toLocaleString()}
                          </Typography>
                          <Typography variant="body2" style={{ color: "#ddd" }}>
                            {newsItem.domain}
                          </Typography>
                          <Typography variant="body2" style={{ color: "#ddd" }}>
                            <a
                              href={newsItem.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "rgba(105, 191, 254, 1)" }}
                            >
                              Read More
                            </a>
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Container>
    </Box>
  );
}
