import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  Select,
  MenuItem,
} from "@mui/material";

import ApiConfig, { baseUrl } from "src/config/APICongig";
import { apiRouterCall } from "src/config/service";

import ButtonCircular from "./ButtonCircular";
import BackButton from "./commonbackbutton/BackButton";

export default function ProfitAttain() {
  const [isDataLoading, setDataLoading] = useState(true);

  const [profit, setProfit] = useState();
  const [sold, setSold] = useState();
  const [calulatedValue, setCalulatedValue] = useState(0);

  // Get current wallet start //
  const getBoughtHandler = async () => {
    try {
      setDataLoading(true);
      const res = await apiRouterCall({
        method: "GET",
        url: ApiConfig.getCurrentTokenHolding,
      });

      if (res.data) {
        setDataLoading(false);
        setProfit(res.data);
      } else {
        setDataLoading(false);
      }
    } catch (error) {
      setDataLoading(false);
    }
  };
  const getSoldHandler = async () => {
    try {
      setDataLoading(true);
      const res = await apiRouterCall({
        method: "GET",
        url: ApiConfig.getTokenSold,
      });

      if (res.data) {
        setDataLoading(false);
        setSold(res.data);
      } else {
        setDataLoading(false);
      }
    } catch (error) {
      setDataLoading(false);
    }
  };
  // Get current wallet end //

  useEffect(() => {
    getSoldHandler();
    getBoughtHandler();
  }, []);

  useEffect(() => {
    console.log("profit=-=-=", profit);
    if (profit && sold) {
      const balance =
        profit && profit?.tokensInfo.map((item) => item?.tokenAmount);
      // console.log("balance=-=-=", balance?.reduce((a,b)=>(a+b));
      if (balance.length > 0) {
        const sumWithInitial =
          balance &&
          balance.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          );
        console.log("sumWithInitial=-=-=-", sumWithInitial);
        setCalulatedValue(sumWithInitial);
      }
    }
  }, [profit, sold]);

  // Add wallet start //

  return (
    <Box className="bg_common border_radius_10">
      <Box mb={2}>
        <BackButton title="Profit Attain" />
      </Box>
      <Container>
        <Box
          className="card_common  border_radius_10"
          style={{ padding: "17px 12px", position: "relative" }}
        >
          <Typography variant="h6" textAlign="center">
            Profit Attain
          </Typography>

          <>
            {isDataLoading ? (
              <Box
                className="displayCenter"
                style={{ paddingBottom: "50px", paddingTop: "7px" }}
              >
                <ButtonCircular />
              </Box>
            ) : (
              <Typography
                style={{ paddingBottom: "50px" }}
                textAlign="center"
                className="textLinearGradient"
              >
                {calulatedValue &&
                  Number(calulatedValue) - Number(sold && sold?.tokensInfo)}
              </Typography>
            )}
          </>
        </Box>
      </Container>
    </Box>
  );
}
