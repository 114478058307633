import BackButton from "src/Components/commonbackbutton/BackButton";
import {
  Box,
  Button,
  Container,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/system";
import axios from "axios";
import ButtonCircular from "src/Components/ButtonCircular";
import { apiRouterCall } from "src/config/service";
import ApiConfig from "src/config/APICongig";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";

const TokenDetailBox = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(16, 16, 16, 1)",
  border: "1px solid rgba(255, 255, 255, 0.05)",
  borderRadius: "25px",
  padding: "15px",
  "& h6": {
    marginBottom: "8px",
    wordBreak: "break-word",
    "@media(max-width:669px)": {
      fontSize: "12px !important",
    },
  },
}));

export default function TwitterDns() {
  const location = useLocation();
  const [type, setType] = useState("");
  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [validation, setValidation] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [dnsData, setDnsData] = useState("");
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const isValidUsername = /(^|[^@\w])@(\w{1,15})\b/g.test(inputValue);

    setUserName(inputValue);
    setValidation(isValidUsername);
  };
  useEffect(() => {
    const dataType = location.state?.type;
    if (dataType) {
      setType(dataType);
    }
  }, [location]);

  const getEnsDomainHandler = async () => {
    if (userName) {
      try {
        setIsLoading(true);
        const res = await apiRouterCall({
          method: "POST",
          url: ApiConfig.getEnsDomain,
          bodyData: {
            username: userName,
          },
        });
        console.log("getEnsDomain====", res);
        if (res.data.statusCode === 200) {
          toast.success(res.data.responseMessage);
          setResponseData(res.data.responseResult);
          setUserName("");
        } else {
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsSubmit(true);
    }
  };
  const getDnsDomainHandler = async () => {
    if (userName) {
      try {
        setIsLoading(true);
        const res = await apiRouterCall({
          method: "POST",
          url: ApiConfig.getTwitterHandle,
          bodyData: {
            userInput: userName,
          },
        });
        console.log("res=dsdd=--", res);

        if (res.data.statusCode === 200) {
          toast.success(res.data.responseMessage);
          setDnsData(res.data.responseResult);
          setUserName("");
        } else {
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsSubmit(true);
    }
  };

  return (
    <Box>
      <BackButton
        title={
          type === "ensTwitter"
            ? "Twitter handle from ENS"
            : type === "dnsTwitter"
            ? "Twitter Handle from DNS"
            : "Twitter"
        }
      />
      <Container>
        {type === "ensTwitter" && (
          <Box>
            <Typography variant="body1">User Name</Typography>
            <TextField
              placeholder="Enter the twitter user name"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              fullWidth
            />
            <FormHelperText error>
              {isSubmit && userName === "" && "Please enter user name"}
            </FormHelperText>

            <Box mt={3}>
              <Button
                disabled={isLoading}
                onClick={() => getEnsDomainHandler()}
                variant="contained"
              >
                Submit {isLoading && <ButtonCircular />}
              </Button>
            </Box>

            {responseData && (
              <Box mt={3} className="card_common">
                <Box p={2}>
                  <Typography variant="h6">Twitter Handle Detail</Typography>
                  <Box mt={1}>
                    <Typography>
                      ID: {responseData.id}
                      {responseData && responseData.id && (
                        <CopyToClipboard text={responseData && responseData.id}>
                          <IconButton
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                            onClick={() => toast.success("Copied")}
                          >
                            <img
                              src="images/copy-icon.svg"
                              alt=""
                              style={{ height: "14px" }}
                            />
                          </IconButton>
                        </CopyToClipboard>
                      )}
                    </Typography>
                    <Typography>Name: {responseData.name}</Typography>
                    <Typography>Username: {responseData.username}</Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        )}
        {type === "dnsTwitter" && (
          <Box>
            <Typography variant="body1">User name with domain</Typography>
            <TextField
              placeholder="Enter the public address or .eth name"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              // onChange={handleInputChange}
              fullWidth
            />
            <FormHelperText error>
              {!validation &&
                userName !== "" &&
                "Please enter vailid user name"}
              {isSubmit && userName === "" && "Please enter user name"}
            </FormHelperText>

            <Box mt={3}>
              <Button
                disabled={isLoading}
                onClick={() => getDnsDomainHandler()}
                variant="contained"
              >
                Submit {isLoading && <ButtonCircular />}
              </Button>
            </Box>
            {dnsData && (
              <Box mt={3} className="card_common">
                <Box p={2}>
                  <Typography variant="h6">Twitter Handle Detail</Typography>
                  <Box mt={1}>
                    <Typography>
                      The associated twitter handle is: {dnsData}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Container>
    </Box>
  );
}
