import BackButton from "src/Components/commonbackbutton/BackButton";
import {
  Box,
  Container,
  Typography,
  TextField,
  FormHelperText,
  Button,
  Divider,
  IconButton,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/system";
import { apiRouterCall } from "src/config/service";
import ApiConfig from "src/config/APICongig";
import Autocomplete from "@mui/material/Autocomplete";
import { toast } from "react-toastify";
import ButtonCircular from "src/Components/ButtonCircular";
import moment from "moment";
import { Delete } from "@mui/icons-material";

const TokenDetailBox = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(16, 16, 16, 1)",
  border: "1px solid rgba(255, 255, 255, 0.05)",
  borderRadius: "25px",
  padding: "15px",
  "& h6": {
    marginBottom: "8px",
    wordBreak: "break-word",
    "@media(max-width:669px)": {
      fontSize: "12px !important",
    },
  },
}));
const CustomPaper = React.forwardRef(function CustomPaper(props, ref) {
  return <Paper ref={ref} style={{ backgroundColor: "#101010" }} {...props} />;
});

export default function PriceAlert() {
  const location = useLocation();
  console.log(">>>>>>>location", location);
  const [type, setType] = useState("");
  const [coinDetails, setCoinDetails] = useState({
    coinShortName: "",
    coinPrice: "",
  });
  const [symbolOptions, setSymbolOptions] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  let getChain = window.localStorage.getItem("_chId");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isCoinShortNameError, setIsCoinShortNameError] = useState(false);
  const [viewPriceData, setViewPriceData] = useState([]);
  const [priceAlertLoading, setPriceAlertLoading] = useState(true);
  console.log("getChain====", getChain);
  useEffect(() => {
    const dataType = location?.state?.type;
    if (dataType) {
      setType(dataType);
    }
  }, [location]);

  const getSymbolListHandler = async () => {
    try {
      if (coinDetails.coinShortName) {
        const res = await apiRouterCall({
          method: "GET",
          url: ApiConfig.coinList,
          paramsData: { search: coinDetails.coinShortName },
        });

        if (res.data.responseCode === 200) {
          const symbols = res.data.result.map(({ id, name }) => ({
            id,
            name,
          }));
          setSymbolOptions(symbols);
          setIsCoinShortNameError(false);
        } else {
          setSymbolOptions([]);
          setIsCoinShortNameError(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (type === "priceAlert" && coinDetails.coinShortName) {
      getSymbolListHandler();
    }
  }, [type, coinDetails.coinShortName]);

  const setPriceHandler = async () => {
    if (coinDetails.coinPrice !== "" && !isCoinShortNameError) {
      setIsLoading(true);
      try {
        // setPriceAlertLoading(true);
        const res = await apiRouterCall({
          method: "POST",
          url: ApiConfig.setPrice,
          bodyData: {
            price: coinDetails.coinPrice,
            symbol: selectedSymbol ? selectedSymbol.id : null,
            chatID: location?.state?.chatId?.toString(),
          },
        });
        if (res.data.responseCode === 200) {
          toast.success(res.data.responseMessage);
          viewPriceHandler(location?.state?.chatId?.toString());
          setIsLoading(false);
          setCoinDetails({
            coinPrice: "",
            coinShortName: "",
          });
          setSelectedSymbol(null);
          setSymbolOptions([]);
        } else {
          toast.error(res.data.responseMessage);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    } else {
      setIsSubmit(true);
    }
  };

  const viewPriceHandler = async (id) => {
    try {
      const res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.viewPriceAlert,
        bodyData: {
          chatID: id,
        },
      });
      console.log(">>>>>>>res", res);
      if (res.data.statusCode === 200) {
        setViewPriceData(res.data.responseResult);
        setPriceAlertLoading(false);
      } else {
        setPriceAlertLoading(false);
      }
    } catch (error) {
      setPriceAlertLoading(false);
    }
  };

  const deletePriceAlertHandler = async (id) => {
    try {
      const res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.deletePriceAlert,
        bodyData: {
          chatID: location?.state?.chatId?.toString(),
          id: id,
        },
      });
      if (res.data.statusCode === 200) {
        viewPriceHandler(location?.state?.chatId?.toString());
        toast.success(res.data.responseMessage);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (location?.state?.chatId?.toString()) {
      viewPriceHandler(location?.state?.chatId?.toString());
    }
  }, [location?.state]);

  return (
    <Box>
      <BackButton title={type === "priceAlert" ? "Price Alert" : ""} />
      <Container>
        <Box mt={2} mb={1}>
          <Typography variant="body2" color="primary">
            Coin Short Name
          </Typography>
        </Box>
        <Autocomplete
          freeSolo
          sx={{
            "& .MuiOutlinedInput-root": { height: "auto" },
            height: "auto",
          }}
          PaperComponent={CustomPaper}
          disableClearable
          fullWidth
          options={symbolOptions}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "auto",
                },
                height: "auto",
              }}
              placeholder="Select Coin Name"
              variant="outlined"
              InputProps={{ ...params.InputProps, type: "search" }}
              value={coinDetails.coinShortName}
              onChange={(e) => {
                setCoinDetails({
                  ...coinDetails,
                  coinShortName: e.target.value,
                });
              }}
            />
          )}
          onChange={(_, value) => {
            setSelectedSymbol(value);
          }}
          helperText={
            isSubmit && isCoinShortNameError
              ? "Please enter a valid coin short name."
              : ""
          }
          error={isSubmit && isCoinShortNameError}
          style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
        />

        <Box mt={2}>
          <Box mt={2} mb={1}>
            <Typography variant="body2" color="primary">
              Coin Price
            </Typography>
          </Box>
          <TextField
            placeholder="Select Coin Price"
            variant="outlined"
            fullWidth
            value={coinDetails.coinPrice}
            onChange={(e) => {
              setCoinDetails({
                ...coinDetails,
                coinPrice: e.target.value,
              });
            }}
            helperText={
              isSubmit &&
              coinDetails.coinPrice === "" &&
              "Please enter coin price."
            }
            error={isSubmit && coinDetails.coinPrice === ""}
          />
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={setPriceHandler}
            disabled={isLoading}
          >
            Submit {isLoading && <ButtonCircular />}
          </Button>
        </Box>
        {priceAlertLoading ? (
          <Box
            className="card_common displayCenter"
            mt={2}
            style={{ padding: "8px 15px" }}
          >
            <ButtonCircular />
          </Box>
        ) : (
          <>
            {viewPriceData && (
              <ViewPriceData
                viewPriceData={viewPriceData}
                deletePriceAlertHandler={deletePriceAlertHandler}
              />
            )}
          </>
        )}
      </Container>
    </Box>
  );
}

const ViewPriceData = ({ viewPriceData, deletePriceAlertHandler }) => {
  return (
    <>
      {viewPriceData &&
        viewPriceData.map((item) => {
          return (
            <Box
              className="card_common"
              mt={2}
              style={{ padding: "8px 15px", position: "relative" }}
            >
              <Box style={{ position: "absolute", right: "0px" }}>
                <IconButton onClick={() => deletePriceAlertHandler(item?._id)}>
                  <Delete />
                </IconButton>
              </Box>
              <Box>
                <Typography variant="h6">Price : {item?.price}</Typography>
                <Typography variant="h6">Symbol : {item?.symbol}</Typography>
                <Typography variant="h6">
                  Date & Time : {moment(item?.createdAt).format("lll")}
                </Typography>
                {/* <Box mt={1} mb={1}>
                  <Divider />
                </Box> */}
              </Box>
            </Box>
          );
        })}
    </>
  );
};
