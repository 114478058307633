import BackButton from "src/Components/commonbackbutton/BackButton";
import {
  Box,
  Button,
  Container,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StartStopModal from "src/Components/StartStopModal";
import { apiRouterCall } from "src/config/service";
import ApiConfig, { nodeUrl } from "src/config/APICongig";
import { toast } from "react-toastify";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import CommonTextField from "src/Components/CommonField/CommonTextField";

import MemePollTransaction from "./MemePollTransaction";
import { sortAddressTrans } from "src/utils";
import CopyToClipboard from "react-copy-to-clipboard";
import ButtonCircular from "src/Components/ButtonCircular";

const TIMEOUT_DURATION = 5 * 60 * 1000;
export default function MemePoll() {
  const socket = window.io(nodeUrl, {
    transports: ["websocket", "polling", "flashsocket"],
  });
  const navigate = useNavigate();
  const location = useLocation();
  console.log("====================================location>>", location);

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [status, setStatus] = useState(false);
  const [transactionData, setTransactionData] = useState();
  const [transactionData1, setTransactionData1] = useState();
  const [memePollData, setMemePollData] = useState([]);
  console.log(">>>>>memePollData", memePollData);
  const [isSubmit, setIsSubmit] = useState(false);
  const [formData, setFormData] = useState({
    walletAddress: "",
  });
  const [expanded, setExpanded] = useState("panel1");
  const [validation, setValidation] = useState(false);
  const type = location?.state?.type;
  const [timeoutId, setTimeoutId] = useState(null);
  const [showCancel, setShowCancel] = useState(false);
  const [mempoolStatus, setMempoolStatus] = useState("Stop Mempool");

  const functionStartStopPool = async () => {
    try {
      setIsLoading(true);
      const res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.startStopTransaction,
        bodyData: {
          chatId: location?.state?.chatId?.toString(),
          startStop: type === "peer" ? true : false,
        },
      });

      if (res.data.statusCode === 200) {


        setOpen(false);
        setIsLoading(false);
        toast.success(res.data.responseMessage);
        // setStatus(true);
        setMempoolStatus("Mempool Stopped");
        socket.emit("getTransaction", {
          chatId: location?.state?.chatId?.toString(),
        });
        socket.on("getTransaction", (data) => {
          console.log(">>>>>>>>data", data);
          if (data?.responseResult?.length > 0) {
            setStatus(true);
          }
          setMemePollData(data?.responseResult);
        });
      } else if (res.data.statusCode === 404) {
        // toast.error(res.data.responseMessage);
        toast.warn("Please set your wallet first");
        navigate("/deposite", {
          state: { type: "setWallet" },
        });

        setIsLoading(false);
        setStatus(false);
      } else {
        setIsLoading(false);
        setStatus(false);
      }
    } catch (error) {
      setIsLoading(false);
      setStatus(false);
    }
  };
  // let socket = io("http://172.16.1.224:2038");

  const gettingDetailsHandler = () => {
    if (formData?.walletAddress) {
      setIsLoading1(true);

      setTimeoutId(
        setTimeout(() => {
          setShowCancel(true);
        }, TIMEOUT_DURATION)
      );
      if (type === "filterDexTrade") {
        socket.emit("fetchDexTradeByWalletAddress", {
          walletAddress: formData?.walletAddress,
        });
        socket.on("fetchDexTradeByWalletAddress", (data) => {
          console.log(">>>>>>>datawallet", data);
          setIsLoading1(false);
          setTransactionData(data?.responseResult);
          setFormData({
            walletAddress: "",
          });
          setValidation(false);
          setIsLoading1(false);
          if (data.statusCode === 400) {
            setIsLoading1(false);
            setTransactionData1(data.responseMessage);
          }
        });
      }
      if (type === "filterDex") {
        socket.emit("fetchDexTradeByWalletAddress", {
          tokenAddress: formData?.walletAddress,
        });
        socket.on("fetchDexTradeByWalletAddress", (data) => {
          console.log(">>>>>>>tokenAddress", data);
          setIsLoading1(false);
          if (data.statusCode === 200) {
            setTransactionData(data?.responseResult);
            setIsLoading1(false);
          }

          setFormData({
            walletAddress: "",
          });
          setValidation(false);
          setIsLoading1(false);
          if (data.statusCode === 400) {
            setIsLoading1(false);
            setTransactionData1(data.responseMessage);
          }
        });
      }
      if (type === "filter") {
        socket.emit("fetchDexTradeByWalletAddress", {
          dexAddress: formData?.walletAddress,
        });
        socket.on("fetchDexTradeByWalletAddress", (data) => {
          if (data.statusCode === 200) {
            setIsLoading1(false);
            setTransactionData(data?.responseResult);
          }

          setFormData({
            walletAddress: "",
          });
          setValidation(false);
          setIsLoading1(false);
          if (data.statusCode === 400) {
            setIsLoading1(false);
            setTransactionData1(data.responseMessage);
          }
        });
      }
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    // Event listeners can be added here
    socket.on("connect", () => {
      console.log("Connected to server");
    });

    socket.emit("getTransaction", {
      chatId: location?.state?.chatId?.toString(),
    });
    console.log(">>>>>>>>datachecking");
    socket.on("getTransaction", (data) => {
      console.log(">>>>>>>>data", data);
      // if (data?.responseResult?.length > 0) {

      // }
      if (data.statusCode === 200) {
        setStatus(true);
      }
      if (data.statusCode === 404) {
        setStatus(false);
      }
      setMemePollData(data?.responseResult);
    });

    return () => {
      // Clean up the socket connection when the component unmounts
      socket.disconnect();
    };
  }, []);
  const regexWalletAddress = /^(0x)?[0-9a-fA-F]{40}$/;
  const tokenRegex = /^0x[0-9A-Fa-f]{40}$/;
  const dexAddress = /^0x[a-fA-F0-9]{40}$/;

  return (
    <Box>
      <BackButton
        title={type === "peer" ? "Peer into Mempool" : location?.state?.name}
      />

      <Container mt={1}>
        {((type === "peer" && !status) || type === "stop") && (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
            >
              {type === "peer" ? "Start Mempool" : mempoolStatus}
            </Button>

            {open && (
              <StartStopModal
                funCall={functionStartStopPool}
                open={open}
                setOpen={(item) => setOpen(item)}
                type={type === "peer" ? "Start" : "Stop"}
                isLoading={isLoading}
              />
            )}
          </>
        )}
        {(type === "filterDexTrade" ||
          type === "filterDex" ||
          type === "filter") && (
            <Box>
              <Typography variant="body1">
                {type === "filterDex"
                  ? "Token Address"
                  : type === "filterDexTrade"
                    ? "Wallet Address"
                    : "Dex Address"}{" "}
              </Typography>
              <CommonTextField
                formData={formData}
                placeHolder={`Enter your ${type === "filterDex"
                  ? "token"
                  : type === "filterDexTrade"
                    ? "wallet"
                    : "Dex"
                  } address`}
                name="walletAddress"
                regex={
                  type === "filterDexTrade"
                    ? regexWalletAddress
                    : type === "filterDex"
                      ? tokenRegex
                      : dexAddress
                }
                type="text"
                validation={validation}
                setFormData={(item) => setFormData(item)}
                setValidation={(item) => setValidation(item)}
                errorMsg={`Please enter ${type === "filterDex"
                  ? "token"
                  : type === "filterDexTrade"
                    ? "wallet"
                    : "dex"
                  } address`}
                errorMsg1={`Please enter vailid ${type === "filterDex"
                  ? "token"
                  : type === "filterDexTrade"
                    ? "wallet"
                    : "dex"
                  } address`}
                isSubmit={isSubmit}
                data={formData.walletAddress}
                isLoading={isLoading}
              />

              <Box mt={1}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isLoading1}
                  onClick={gettingDetailsHandler}
                >
                  Submit {isLoading1 && <ButtonCircular />}
                </Button>
                {showCancel && (
                  <Box mt={2}>
                    <Typography variant="h6" style={{ textAlign: "center" }}>
                      We are currently retrieving the data. Please be patient or
                      choose to cancel.
                    </Typography>
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate(-1)}
                        style={{ marginLeft: "10px" }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
              {transactionData && (
                <Box mt={2}>
                  <MemePollTransaction
                    data={transactionData}
                    expanded={expanded}
                    setExpanded={setExpanded}
                  />
                </Box>
              )}
              {transactionData1 && (
                <Typography
                  variant="h6"
                  style={{
                    textAlign: "center",
                    paddingTop: "20px",
                    color: "#fff",
                  }}
                >
                  {transactionData1}
                </Typography>
              )}
            </Box>
          )}
        {status && type === "peer" && memePollData?.length > 0 ? (
          <MemePollData memePollData={memePollData} />
        ) : (
          <>
            {memePollData?.length === 0 && type === "peer" && (
              <Box
                className="card_common"
                mt={2}
                style={{ padding: "8px 15px", textAlign: "center" }}
              >
                <Typography variant="h6">
                  We are fetching your transaction{" "}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Container>
    </Box>
  );
}

const MemePollData = ({ memePollData }) => {
  console.log("memePollData>>>>>>", memePollData);
  return (
    <>
      {memePollData &&
        memePollData.map((item) => {
          return (
            <Box className="card_common" mt={2} style={{ padding: "8px 15px" }}>
              <Box>
                <Typography variant="h6">Chain Id : {item?.chainId}</Typography>
                <Typography variant="h6">
                  From Address :{" "}
                  {item?.fromAddress && sortAddressTrans(item?.fromAddress)}
                  {item?.fromAddress && (
                    <CopyToClipboard text={item && item?.fromAddress}>
                      <IconButton
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                        onClick={() => toast.success("Copied")}
                      >
                        <img
                          src="images/copy-icon.svg"
                          alt=""
                          style={{ height: "14px" }}
                        />
                      </IconButton>
                    </CopyToClipboard>
                  )}
                </Typography>
                <Typography variant="h6">
                  To Address :{" "}
                  {item?.toAddress && sortAddressTrans(item?.toAddress)}
                  {item?.toAddress && (
                    <CopyToClipboard text={item && item?.toAddress}>
                      <IconButton
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                        onClick={() => toast.success("Copied")}
                      >
                        <img
                          src="images/copy-icon.svg"
                          alt=""
                          style={{ height: "14px" }}
                        />
                      </IconButton>
                    </CopyToClipboard>
                  )}
                </Typography>
                <Typography variant="h6" component={Link}>
                  Transaction Hash :{" "}
                  {item?.transactionHash &&
                    sortAddressTrans(item?.transactionHash)}
                  {item?.transactionHash && (
                    <CopyToClipboard text={item && item?.transactionHash}>
                      <IconButton
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                        onClick={() => toast.success("Copied")}
                      >
                        <img
                          src="images/copy-icon.svg"
                          alt=""
                          style={{ height: "14px" }}
                        />
                      </IconButton>
                    </CopyToClipboard>
                  )}
                  <IconButton
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                    onClick={() =>
                      window.open(
                        `https://goerli.etherscan.io/tx/${item?.transactionHash}`
                      )
                    }
                  >
                    <ShortcutIcon />
                  </IconButton>
                </Typography>
                <Typography variant="h6">
                  Gas Price : {item?.gasPrice}
                </Typography>
                <Typography variant="h6">Nonce : {item?.nonce}</Typography>
              </Box>
            </Box>
          );
        })}
    </>
  );
};
