import BackButton from "src/Components/commonbackbutton/BackButton";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/system";
import axios from "axios";
import ButtonCircular from "src/Components/ButtonCircular";

const TokenDetailBox = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(16, 16, 16, 1)",
  border: "1px solid rgba(255, 255, 255, 0.05)",
  borderRadius: "25px",
  padding: "15px",
  "& h6": {
    marginBottom: "8px",
    wordBreak: "break-word",
    "@media(max-width:669px)": {
      fontSize: "12px !important",
    },
  },
}));

export default function StakingFarming() {
  const location = useLocation();
  const [type, setType] = useState("");
  const [farmingStaking, setFarmingStaking] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const dataType = location?.state?.type;
    if (dataType) {
      setType(dataType);
    }
  }, [location]);

  const getStakingOpportunities = async () => {
    try {
      setIsLoading(true);

      const response = await axios.get(
        "https://api.coingecko.com/api/v3/derivatives"
      );
      setFarmingStaking(response.data);
      console.log("getStakingOpportunities:", response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (type === "farming") {
      getStakingOpportunities();
    }
  }, [type]);

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
    // getStakingOpportunities();
  };

  const getMarketDetails = () => {
    if (!farmingStaking || farmingStaking.length === 0) {
      return [];
    }

    const startIndex = (currentPage - 1) * 9;
    const endIndex = startIndex + 9;
    return farmingStaking.slice(0, endIndex);
  };

  const marketDetails = getMarketDetails();

  return (
    <Box>
      <BackButton title={type === "farming" ? "Farming Opportunities" : ""} />
      <Container>
        {isLoading && (
          <Box mt={3} display="flex" justifyContent="center">
            <ButtonCircular />
          </Box>
        )}
        {!isLoading && marketDetails.length > 0 && (
          <Grid container spacing={2}>
            {marketDetails.map((item, i) => {
              return (
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <FarmingList item={item} />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        )}
        {!isLoading && marketDetails.length === 0 && (
          <Box mt={3} display="flex" justifyContent="center">
            <Typography variant="h6">No data found</Typography>
          </Box>
        )}
        {farmingStaking.length > currentPage * 9 && (
          <Box mt={3} mb={3} display="flex" justifyContent="flex-end">
            <Button
              onClick={handleLoadMore}
              variant="contained"
              color="primary"
              style={{ width: "100%", maxWidth: "134px" }}
            >
              Load More
            </Button>
          </Box>
        )}
      </Container>
    </Box>
  );
}

const FarmingList = ({ item }) => {
  return (
    <Box>
      <TokenDetailBox>
        <Typography variant="h6">Market: {item?.market}</Typography>
        <Typography variant="h6">Symbol: {item?.symbol}</Typography>

        <Typography variant="h6">
          Price: {item?.price}&nbsp;
          {item?.index_id}
        </Typography>
        <Typography variant="h6">
          Price Percentage Change in 24hrs: {item?.price_percentage_change_24h}
        </Typography>
        <Typography variant="h6">
          Contract Type: {item?.contract_type}
        </Typography>
        <Typography variant="h6">Funding Rate: {item?.funding_rate}</Typography>
        <Typography variant="h6">
          Open Interest Rate: {item?.open_interest}
        </Typography>
      </TokenDetailBox>
    </Box>
  );
};
