import BackButton from "src/Components/commonbackbutton/BackButton";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";

const TokenDetailBox = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(16, 16, 16, 1)",
  border: "1px solid rgba(255, 255, 255, 0.05)",
  borderRadius: "25px",
  padding: "15px",
  "& h6": {
    marginBottom: "8px",
    wordBreak: "break-word",
    "@media(max-width:669px)": {
      fontSize: "12px !important",
    },
  },
}));

export default function SeekTokenDetail({ seekTokenEssenceData }) {
  return (
    <Box>
      <TokenDetailBox>
        <Typography variant="h6">
          Token Name: {seekTokenEssenceData.name}
        </Typography>
        <Typography variant="h6">
          Symbol: {seekTokenEssenceData.symbol}
        </Typography>
        <Typography variant="h6">
          Current Price:{" "}
          {seekTokenEssenceData.market_data.current_price?.usd || "N/A"} USD
        </Typography>
        <Typography variant="h6">
          Market Cap:{" "}
          {seekTokenEssenceData.market_data.market_cap?.usd || "N/A"} USD
        </Typography>
        <Typography variant="h6">
          Total Volume: {seekTokenEssenceData.total_volume?.usd || "N/A"} USD
        </Typography>
        <Typography variant="h6">
          Reddit Subs:{" "}
          {seekTokenEssenceData.community_data.reddit_subscribers || "N/A"}
        </Typography>
        <Typography variant="h6">
          Reddit Accounts Active 48 Hours:{" "}
          {seekTokenEssenceData.community_data.reddit_accounts_active_48h ||
            "N/A"}
        </Typography>
        <Typography variant="h6">
          Reddit Average Comments 48 Hours:{" "}
          {seekTokenEssenceData.community_data.reddit_average_comments_48h ||
            "N/A"}
        </Typography>
        <Typography variant="h6">
          Reddit Average Posts 48 Hours:{" "}
          {seekTokenEssenceData.community_data.reddit_average_posts_48h ||
            "N/A"}
        </Typography>
        <Typography variant="h6">
          Twitter Followers:{" "}
          {seekTokenEssenceData.community_data.twitter_followers || "N/A"}
        </Typography>
        <Typography variant="h6">
          Facebook Likes:{" "}
          {seekTokenEssenceData.community_data.facebook_likes || "N/A"}
        </Typography>
        <Typography variant="h6">
          Alexa Rank:{" "}
          {seekTokenEssenceData.public_interest_stats.alexa_rank || "N/A"}
        </Typography>
        <Typography variant="h6">
          Bing Matches:{" "}
          {seekTokenEssenceData.public_interest_stats.bing_matches || "N/A"}
        </Typography>
      </TokenDetailBox>
    </Box>
  );
}
