import BackButton from "src/Components/commonbackbutton/BackButton";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";

const TokenDetailBox = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(16, 16, 16, 1)",
  border: "1px solid rgba(255, 255, 255, 0.05)",
  borderRadius: "25px",
  padding: "15px",
  "& h6": {
    marginBottom: "8px",
    wordBreak: "break-word",
    "@media(max-width:669px)": {
      fontSize: "12px !important",
    },
  },
}));

export default function UnveilTokenDetail({ unveilDetail }) {
  console.log("unveilDetail===", unveilDetail);
  return (
    <Box>
      <TokenDetailBox>
        <Typography variant="h6">
          Token Name: {unveilDetail.tokenName}
        </Typography>
        <Typography variant="h6">
          Token Symbol: {unveilDetail.tokenSymbol}
        </Typography>
        <Typography variant="h6">
          Exchange Name: {unveilDetail.exchangeName}
        </Typography>
        <Typography variant="h6">
          Exchange Address: {unveilDetail.exchangeAddress}
        </Typography>
        <Typography variant="h6">
          Token Address: {unveilDetail.tokenAddress}
        </Typography>
      </TokenDetailBox>
    </Box>
  );
}
