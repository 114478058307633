import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
export default function BackButton({ title }) {
  const navigate = useNavigate();
  return (
    <>
      <Box
        style={{
          position: "fixed",
          width: "100%",
          top: "0px",
          height: "43px",
          background: "black",
          zIndex: "1111",
        }}
      >
        <Box
          style={{
            position: "absolute",
            left: "20px",
            top: "10px",
            zIndex: "1111",
          }}
        >
          <IconButton
            style={{
              background: "transparent",
              height: "0px",
              width: "0px",
            }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Typography
          variant="h6"
          style={{
            color: "#FFF",
            textAlign: "center",
            padding: "10px 0px",
            fontSize: "16px",
            fontWeight: "400",
            fontFamily: "Gilroy-SemiBold",
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box position="relative" style={{ marginTop: "60px" }}></Box>
    </>
  );
}
