import BackButton from "src/Components/commonbackbutton/BackButton";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { sortAddress, sortAddressTrans } from "src/utils";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";

const TransactionBox = styled(Box)(({ theme }) => ({
  position: "relative",
  "& .tableBox": {
    minWidth: "800px",
  },
  "& .tableContainer ": {
    borderBottomLeftRadius: "25px",
    borderBottomRightRadius: "25px",
    position: "relative",
    zIndex: "9999",
  },
  "& .rowOdd": {
    background: "rgba(255, 255, 255, 0.04)",
  },
  "& .rowEven": {
    background: "rgba(255, 255, 255, 0.01)",
  },
  "& h6": {
    marginBottom: "8px",
    wordBreak: "break-word",
    "@media(max-width:669px)": {
      fontSize: "12px !important",
    },
  },
}));
export default function MemePollTransaction({ expanded, data, setExpanded }) {
  console.log("data=-=-=-", data);
  return (
    <Box>
      <TransactionBox mt={2}>
        <Typography variant="h6" style={{ color: "#fff", textAlign: "center" }}>
          Transaction Details
        </Typography>

        <Box mt={2}>
          <Accordion
            sx={{
              background: "rgba(16, 16, 16, 1)",
              border: "1px solid rgba(255, 255, 255, 0.05)",
              margin: "10px 0px",
            }}
            square
            expanded={expanded === "panel1"}
            onChange={(_, isExpanded) =>
              setExpanded(isExpanded ? "panel1" : false)
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                "& .MuiAccordionSummary-content": {
                  display: "flex",
                  justifyContent: "space-between",
                  "@media(max-width:564px)": {
                    display: "block",
                  },
                },
              }}
            >
              <Box
                sx={{
                  "@media (max-width: 564px)": {
                    marginBottom: "10px",
                  },
                }}
              >
                <Typography
                  variant="h6"
                  style={{ color: "rgba(255, 255, 255, 0.75)" }}
                >
                  Chain Id
                </Typography>
                <Typography variant="h6">
                  {data.chainId ? data.chainId.toString() : "N/A"}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h6"
                  style={{ color: "rgba(255, 255, 255, 0.75)" }}
                >
                  TXN Hash :
                </Typography>
                <Typography variant="h6">
                  {data.transactionHash
                    ? sortAddressTrans(data.transactionHash)
                    : "N/A"}
                  {data && data?.transactionHash && (
                    <CopyToClipboard text={data && data?.transactionHash}>
                      <IconButton
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                        onClick={() => toast.success("Copied")}
                      >
                        <img
                          src="images/copy-icon.svg"
                          alt=""
                          style={{ height: "14px" }}
                        />
                      </IconButton>
                    </CopyToClipboard>
                  )}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              style={{ borderTop: "1px solid rgba(56, 76, 102, 1)" }}
            >
              <Box mt={1}>
                <Typography
                  variant="h6"
                  style={{ color: "rgba(255, 255, 255, 0.75)" }}
                >
                  From :
                </Typography>
                <Typography variant="h6">
                  {" "}
                  {data?.fromAddress
                    ? sortAddressTrans(data?.fromAddress)
                    : "N/A"}
                  {data && data?.fromAddress && (
                    <CopyToClipboard text={data && data?.fromAddress}>
                      <IconButton
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                        onClick={() => toast.success("Copied")}
                      >
                        <img
                          src="images/copy-icon.svg"
                          alt=""
                          style={{ height: "14px" }}
                        />
                      </IconButton>
                    </CopyToClipboard>
                  )}
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography
                  variant="h6"
                  style={{ color: "rgba(255, 255, 255, 0.75)" }}
                >
                  To :
                </Typography>
                <Typography variant="h6">
                  {" "}
                  {data?.toAddress ? sortAddressTrans(data?.toAddress) : "N/A"}
                  {data && data?.toAddress && (
                    <CopyToClipboard text={data?.toAddress && data?.toAddress}>
                      <IconButton
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                        onClick={() => toast.success("Copied")}
                      >
                        <img
                          src="images/copy-icon.svg"
                          alt=""
                          style={{ height: "14px" }}
                        />
                      </IconButton>
                    </CopyToClipboard>
                  )}
                </Typography>
              </Box>

              <Box mt={1}>
                <Typography
                  variant="h6"
                  style={{ color: "rgba(255, 255, 255, 0.75)" }}
                >
                  Value :
                </Typography>
                <Typography variant="h6">
                  {data?.value ? data.value.toString() : "N/A"}
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography
                  variant="h6"
                  style={{ color: "rgba(255, 255, 255, 0.75)" }}
                >
                  Gas Used :
                </Typography>
                <Typography variant="h6">
                  {" "}
                  {data?.gasLimit ? data?.gasLimit.toString() : "N/A"}
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography
                  variant="h6"
                  style={{ color: "rgba(255, 255, 255, 0.75)" }}
                >
                  Gas Price :
                </Typography>
                <Typography variant="h6">
                  {data?.gasPrice ? data?.gasPrice.toString() : "N/A"}
                </Typography>
              </Box>

              <Box mt={1}>
                <Typography
                  variant="h6"
                  style={{ color: "rgba(255, 255, 255, 0.75)" }}
                >
                  Max Fee Per Gas :
                </Typography>
                <Typography variant="h6">
                  {data?.maxFeePerGas ? data?.maxFeePerGas : "N/A"}
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography
                  variant="h6"
                  style={{ color: "rgba(255, 255, 255, 0.75)" }}
                >
                  Max Priority Fee Per Gas :
                </Typography>
                <Typography variant="h6">
                  {data?.maxPriorityFeePerGas
                    ? data?.maxPriorityFeePerGas
                    : "N/A"}
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </TransactionBox>
    </Box>
  );
}
