import ApiConfig from "src/config/APICongig";
import { apiRouterCall } from "src/config/service";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Pagination,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ButtonCircular from "src/Components/ButtonCircular";
import { toast } from "react-toastify";
import { getRandomColor } from "src/utils";
import { CheckBox, Delete } from "@mui/icons-material";

export default function PollList({ type, chatid }) {
  const [pollList, setPollList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isVoteLodaing, setIsVoteLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const voteOnPollHandler = async (id, option) => {
    try {
      setIsVoteLoading(true);
      const res = await apiRouterCall({
        method: "POST",
        url: type === "pollList" ? ApiConfig.votePoll : ApiConfig.voteQuiz,
        bodyData: {
          optionNo: option.toString(),
          pollId: type === "pollList" ? id.toString() : undefined,
          chatID: chatid,
          quizId: type === "quizList" ? id.toString() : undefined,
        },
      });
      if (res.data.statusCode === 200) {
        toast.success(res.data.responseMessage);
        setIsVoteLoading(false);
        listPollHandler();
      } else {
        toast.error(res.data.responseMessage);
        setIsVoteLoading(false);
      }
    } catch (error) {
      setIsVoteLoading(false);
    }
  };

  const listPollHandler = async () => {
    try {
      const res = await apiRouterCall({
        method: "GET",
        url: type === "pollList" ? ApiConfig.getPoll : ApiConfig.getQuiz,
        paramsData: {
          limit: 10,
          page: page,
        },
      });

      if (res.data.statusCode === 200) {
        setPollList(res.data.responseResult.docs);
        setIsLoading(false);
        setNoOfPages(res.data.responseResult.pages);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    listPollHandler();
  }, [page]);

  const deletePollHandler = async (id) => {
    try {
      setIsVoteLoading(true);

      const res = await apiRouterCall({
        method: "POST",
        url: type === "pollList" ? ApiConfig.deletePoll : ApiConfig.deleteQuiz,

        bodyData: {
          pollId: type === "pollList" ? id.toString() : undefined,
          quizId: type === "quizList" ? id.toString() : undefined,
          chatID: chatid,
        },
      });
      if (res.data.statusCode === 200) {
        toast.success(res.data.responseMessage);
        setIsVoteLoading(false);
        listPollHandler();
      } else {
        setIsVoteLoading(false);
      }
    } catch (error) {
      setIsVoteLoading(false);
    }
  };

  const pageCheck = page === 1 ? 10 : 0;
  return (
    <Box>
      {isLoading ? (
        <Box className="displayCenter">
          <ButtonCircular />
        </Box>
      ) : (
        <Grid container spacing={1}>
          {pollList &&
            pollList?.map((item, index) => {
              return (
                <Grid item xs={12} key={`poll${index + 1}`}>
                  <Box
                    className="card_common"
                    style={{ padding: "15px 13px", cursor: "auto" }}
                  >
                    <Box className="dispalySpacebetween">
                      <Typography variant="h5" style={{ color: "#fff" }}>
                        {item?.question}&nbsp;
                        {item?.pollID && <>{`Poll Id : ${item?.pollID}`}</>}
                        {item?.quizId && <>{`Quiz Id : ${item?.quizId}`}</>}
                      </Typography>
                      {type === "pollList" ? (
                        <>
                          {item?.pollCreateChatId === chatid && (
                            <IconButton
                              onClick={() => deletePollHandler(item?.pollID)}
                            >
                              <Delete />
                            </IconButton>
                          )}
                        </>
                      ) : (
                        <>
                          {item?.quizCreateChatId === chatid && (
                            <IconButton
                              onClick={() => deletePollHandler(item?.quizId)}
                            >
                              <Delete />
                            </IconButton>
                          )}
                        </>
                      )}
                    </Box>
                    <Box mt={1}>
                      {item?.options &&
                        item?.options?.map((answer, index) => {
                          return (
                            <>
                              {type === "quizList" ? (
                                <Box mt={1}>
                                  <Button
                                    onClick={() =>
                                      voteOnPollHandler(
                                        item?.pollID
                                          ? item?.pollID
                                          : item?.quizId,
                                        index + 1
                                      )
                                    }
                                    // fullWidth
                                    style={{
                                      backgroundColor: "gray",
                                    }}
                                  >
                                    {" "}
                                    {answer?.optionText}
                                  </Button>
                                </Box>
                              ) : (
                                <Box
                                  className="dispalySpacebetween"
                                  // className={item?.pollID && index===0? "result-bar yes" : index===1 ? "result-bar1 yes" :"result-bar1 yes"}
                                  style={{
                                    paddingLeft: "5px",
                                    cursor: "pointer",
                                    width: `${answer?.percentage}%`,
                                    marginBottom: "3px",
                                    backgroundColor: getRandomColor(),
                                  }}
                                  onClick={() =>
                                    voteOnPollHandler(
                                      item?.pollID
                                        ? item?.pollID
                                        : item?.quizId,
                                      index + 1
                                    )
                                  }
                                >
                                  <Typography
                                    variant="h6"
                                    style={{
                                      color: "#fff",
                                      textTransform: "capitalize",
                                      whiteSpace: "pre",
                                    }}
                                  >
                                    {answer?.optionText}{" "}
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    style={{
                                      color: "#fff",
                                      textTransform: "capitalize",
                                      whiteSpace: "pre",
                                      padding: "0px 10px",
                                    }}
                                  >
                                    {`${
                                      answer?.percentage > 0
                                        ? answer?.percentage?.toFixed(2)
                                        : answer?.percentage
                                    }%`}
                                  </Typography>

                                  {answer?.usersChatID &&
                                    answer.usersChatID.map((userid) =>
                                      userid === chatid ? (
                                        <>
                                          <CheckBox
                                            style={{ color: "white" }}
                                          />
                                        </>
                                      ) : null
                                    )}
                                </Box>
                              )}
                            </>
                          );
                        })}
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          {pollList && pollList.length >= pageCheck && (
            <Grid item xs={12}>
              <Box pt={1} pb={2}>
                <Pagination
                  page={page}
                  count={noOfPages}
                  onChange={(e, v) => setPage(v)}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            {pollList && pollList.length === 0 && (
              <Typography
                variant="h6"
                style={{
                  textAlign: "center",
                  paddingTop: "20px",
                  color: "#fff",
                }}
              >
                Data Not Found
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
