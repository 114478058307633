import BackButton from "src/Components/commonbackbutton/BackButton";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";

const TokenDetailBox = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(16, 16, 16, 1)",
  border: "1px solid rgba(255, 255, 255, 0.05)",
  borderRadius: "25px",
  padding: "15px",
  "& h6": {
    marginBottom: "8px",
    wordBreak: "break-word",
    "@media(max-width:669px)": {
      fontSize: "12px !important",
    },
  },
}));

export default function DiscoverToken({ marketValue }) {
  return (
    <Box>
      <TokenDetailBox>
        <Typography variant="h6">
          Price: {marketValue.price || "N/A"}
        </Typography>
        <Typography variant="h6">
          Current Market-cap: {marketValue.marketCap || "N/A"}
        </Typography>
        <Typography variant="h6">
          Total Volume: {marketValue.totalVolume || "N/A"}
        </Typography>
        <Typography variant="h6">
          Percentage Change in Value: {marketValue.percentage || "N/A"}
        </Typography>
        <Typography variant="h6">
          Percentage Change in Market Caps:{" "}
          {marketValue.marketCapChange || "N/A"}
        </Typography>
        <Typography variant="h6">
          Percentage Change in Total Volumes:{" "}
          {marketValue.changeVolume || "N/A"}
        </Typography>
      </TokenDetailBox>
    </Box>
  );
}
