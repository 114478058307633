import React, { Fragment, Suspense } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";

import routes from "./routes";
import CoreLayout from "./CoreLayout";
import { Typography } from "@mui/material";
import ClipLoader from "react-spinners/DotLoader";
const CustomLoading = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <ClipLoader
        color="#36d7b7"
        loading={true}
        // cssOverride={override}
        size={60}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};
const renderRoute = () => {
  return routes.map((route, i) => {
    let { element: Element, meta, path, redirect, layout } = route;
    const Layout = route.layout || Fragment;
    return (
      <>
        <Route
          key={i}
          path={path}
          element={
            <Layout>
              <Suspense fallback={<CustomLoading />}>
                <Element />
              </Suspense>
            </Layout>
          }
        />
      </>
    );
  });
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<CoreLayout />}>
      {renderRoute()}

      <Route path="*" element={<Navigate to="/404" replace />} />
    </Route>
  )
);
export default router;
