import React, { useEffect, useState } from "react";
import { Box, Grid, Container, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "./commonbackbutton/BackButton";
export default function CommonCard() {
  const location = useLocation();

  const navigate = useNavigate();
  const [listData, setListData] = useState([]);

  const title = location.state.title;
  useEffect(() => {
    const dataList = location.state.data;

    if (dataList) {
      setListData(dataList);
    }
  }, [location]);

  return (
    <Box style={{ position: "relative", height: "94dvh" }}>
      <Container>
        <Box
          className="bg_common border_radius_10"
          style={{
            position: "absolute",
            width: "100%",
            right: "0px",
          }}
        >
          <BackButton title={title} />
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            {listData &&
              listData.map((item, i) => {
                return (
                  <Grid item xs={12} key={`card${i}`}>
                    <Box
                      className="card_common display_center border_radius_10"
                      onClick={() =>
                        navigate(item.href, {
                          state: item,
                        })
                      }
                      style={{
                        padding: "15px 13px",
                        margin: "0px 10px",
                      }}
                    >
                      <img
                        src={item.image}
                        alt={item.name}
                      // style={{ height: "28px", width: "25px" }}
                      />
                      <Typography
                        variant="h6"
                        style={{ color: "#FFF", paddingLeft: "10px" }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
