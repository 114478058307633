import BackButton from "src/Components/commonbackbutton/BackButton";
import {
  Box,
  Button,
  Container,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Delete } from "@mui/icons-material";
import { apiRouterCall } from "src/config/service";
import ApiConfig from "../../config/APICongig";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonCircular from "src/Components/ButtonCircular";
import PollList from "./PollList";

export default function PollQuiz() {
  const location = useLocation();

  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cryptoAnswer, setCryptoAnswer] = useState("");
  const [chatId, setChatId] = useState("");
  const [options, setOptions] = useState([
    { id: 1, value: "" },
    { id: 2, value: "" },
    { id: 3, value: "" },
  ]);
  const [question, setQestion] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState("");
  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index].value = value;
    setOptions(newOptions);
    setIsSubmit(false);
  };
  const handleAddOption = () => {
    setOptions([...options, { id: options.length + 1, value: "" }]);
  };
  const handleRemoveOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const hasEmptyOption = options.some((option) => option.value === "");
    if (
      (location?.state?.type === "mirrorBot" && correctAnswer === "") ||
      question === "" ||
      // chatId === "" ||
      hasEmptyOption
    ) {
      setIsSubmit(true);
    } else {
      try {
        setIsLoading(true);
        const res = await apiRouterCall({
          method: "POST",
          url:
            location?.state?.type === "godMode"
              ? ApiConfig.createPoll
              : ApiConfig.createQuiz,
          bodyData: {
            // chatID: chatId.toString(),
            chatID: location.state.chatId.toString(),
            question: question,
            options: options && options.map((item) => item.value),
            correctAnswer:
              location?.state?.type === "mirrorBot" ? correctAnswer : undefined,
          },
        });
        if (res.data.statusCode === 200) {
          setIsLoading(false);
          toast.success(res.data.responseMessage);
          setQestion("");
          setOptions([
            { id: 1, value: "" },
            { id: 2, value: "" },
            { id: 3, value: "" },
          ]);
          setCorrectAnswer("");
        } else {
          setIsLoading(false);
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const createCryptoQuestion = async () => {
    if (question) {
      try {
        setIsLoading(true);
        const res = await apiRouterCall({
          method: "POST",
          url: ApiConfig.generateAndStore,
          bodyData: {
            question: question,
            chatID: location.state.chatId.toString(),
          },
        });
        if (res.data) {
          console.log("res=-=-=", res);
          setIsLoading(false);
          setCryptoAnswer(res.data.responseResult);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      setIsSubmit(true);
    }
  };

  return (
    <Box>
      <BackButton title={location?.state?.name} />
      <Container>
        {(location?.state?.type === "pollList" ||
          location?.state?.type === "quizList") && (
          <PollList
            type={location?.state?.type}
            chatid={location.state.chatId.toString()}
          />
        )}
        {(location?.state?.type === "godMode" ||
          location?.state?.type === "mirrorBot") && (
          <>
            {/* <Box mt={1}>
              <TextField
                type="number"
                variant="outlined"
                value={question}
                disabled={isLoading}
                placeholder="Enter your id"
                fullWidth
                onChange={(e) => setChatId(e.target.value)}
              />
              <FormHelperText error>
                {isSubmit && chatId === "" && "Please enter your id"}
              </FormHelperText>
            </Box> */}
            <Box mt={1}>
              <TextField
                variant="outlined"
                value={question}
                disabled={isLoading}
                placeholder={
                  location?.state?.type === "godMode"
                    ? "Enter the poll question"
                    : "Enter the quiz question"
                }
                fullWidth
                onChange={(e) => setQestion(e.target.value)}
              />
              <FormHelperText error>
                {isSubmit && question === "" && "Please enter the question"}
              </FormHelperText>
            </Box>

            {location?.state?.type === "mirrorBot" && (
              <Box mt={1}>
                <TextField
                  variant="outlined"
                  value={correctAnswer}
                  disabled={isLoading}
                  placeholder="Please enter correct answer"
                  fullWidth
                  onChange={(e) => setCorrectAnswer(e.target.value)}
                />
                <FormHelperText error>
                  {isSubmit && question === "" && "Please enter the question"}
                </FormHelperText>
              </Box>
            )}

            {options.map((option, index) => (
              <Box key={index} mt={1}>
                <TextField
                  variant="outlined"
                  type="text"
                  placeholder={`Option ${index + 1}`}
                  value={option.value}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                  fullWidth
                  disabled={isLoading}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {((location?.state?.type === "godMode" &&
                          options &&
                          options.length > 2) ||
                          (location?.state?.type === "mirrorBot" &&
                            options &&
                            options.length > 3)) && (
                          <IconButton onClick={() => handleRemoveOption(index)}>
                            <Delete />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText error>
                  {isSubmit &&
                    option.value === "" &&
                    `Please enter the option ${index + 1}`}
                </FormHelperText>
              </Box>
            ))}
            <Button
              style={{ marginTop: "5px" }}
              variant="outlined"
              onClick={handleAddOption}
              disabled={isLoading}
            >
              Add Option
            </Button>

            <Box mt={1}>
              <Button
                disabled={isLoading}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Submit {isLoading && <ButtonCircular />}
              </Button>
            </Box>
          </>
        )}
        {location?.state?.type === "cryptoQuiz" && (
          <Box>
            <TextField
              variant="outlined"
              placeholder="Enter your question"
              fullWidth
              value={question}
              onChange={(e) => setQestion(e.target.value)}
            />
            <FormHelperText error>
              {isSubmit && question === "" && "Please enter the question"}
            </FormHelperText>

            <Box mt={1}>
              <Button
                disabled={isLoading}
                onClick={() => {
                  if (cryptoAnswer) {
                    setQestion("");
                    setCryptoAnswer("");
                  } else {
                    createCryptoQuestion();
                  }
                }}
                variant="contained"
              >
                {cryptoAnswer ? "Ask for other" : "Submit"}{" "}
                {isLoading && <ButtonCircular />}
              </Button>
            </Box>
            {cryptoAnswer && cryptoAnswer && (
              <Box
                mt={2}
                className="card_common"
                style={{ padding: "10px 12px" }}
              >
                <Typography variant="h5" style={{ color: "#fff" }}>
                  {question}
                </Typography>
                <Typography
                  variant="h6"
                  style={{ color: "#fff", paddingTop: "5px" }}
                >
                  {cryptoAnswer}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Container>
    </Box>
  );
}
