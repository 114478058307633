import BackButton from "src/Components/commonbackbutton/BackButton";
import ApiConfig from "src/config/APICongig";
import { apiRouterCall } from "src/config/service";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  IconButton,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import ButtonCircular from "src/Components/ButtonCircular";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import { sortAddress, sortAddressTrans } from "src/utils";
import { useLocation, useNavigate } from "react-router-dom";
import CommonDivider from "src/Components/CommonDivider";
const commonStyle = {
  fontFamily: "Gilroy-Regular",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  paddingTop: "30px",
};
const textStyle = {
  fontFamily: "Gilroy-SemiBold",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "170.3%",
};
export default function TokenBought() {
  const location = useLocation();
  const type = location?.state?.type;
  const [expanded, setExpanded] = React.useState(false);
  const [isDataLoading, setDataLoading] = useState(true);
  const [walletData, setWalletData] = useState({});
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const getCurentWalletHandler = async () => {
    try {
      setDataLoading(true);
      const res = await apiRouterCall({
        method: "GET",
        url:
          type === "tokenSold"
            ? ApiConfig.getTokenSold
            : ApiConfig.getCurrentTokenHolding,
      });

      if (res.data) {
        setDataLoading(false);
        setWalletData(res.data);
      } else {
        setDataLoading(false);
      }
    } catch (error) {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    getCurentWalletHandler();
  }, []);

  console.log("walletData?.tokensInfo-=-=", walletData?.tokensInfo);

  return (
    <Box>
      <BackButton
        title={type === "tokenSold" ? "Sold Token" : "Token Bought"}
      />
      <Container>
        {isDataLoading ? (
          <Box className="displayCenter" width="100%">
            <ButtonCircular />
          </Box>
        ) : (
          <>
            {type === "tokenBought" && (
              <>
                {walletData &&
                  walletData?.tokensInfo &&
                  walletData?.tokensInfo?.map((item) => {
                    return (
                      <Box>
                        <Typography sx={commonStyle}>Token Id :</Typography>
                        <Typography sx={textStyle}>
                          {item?.tokenSymbol}
                        </Typography>
                        <Typography sx={commonStyle}>
                          Token Bought Amount :{" "}
                        </Typography>
                        <Typography sx={textStyle}>
                          {item?.tokenAmount}
                        </Typography>
                        <Typography sx={commonStyle}>
                          Token Contract Address :{" "}
                        </Typography>
                        <Typography sx={textStyle}>
                          {item && sortAddressTrans(item?.tokenAddress)}

                          <CopyToClipboard text={item && item?.tokenAddress}>
                            <IconButton
                              style={{ height: "25px", width: "25px" }}
                              onClick={() => toast.success("Copied")}
                            >
                              <img src="images/copy-icon.svg" alt="" />
                            </IconButton>
                          </CopyToClipboard>
                        </Typography>
                      </Box>
                    );
                  })}
                {walletData && Number(walletData?.tokensInfo) === 0 && (
                  <Typography textAlign="center">Data Not found</Typography>
                )}
              </>
            )}
            {type === "tokenSold" && (
              <>
                {walletData &&
                  walletData?.tokensInfo &&
                  walletData?.tokensInfo?.map((item, i) => {
                    return (
                      <SoldAccordian
                        handleChange={handleChange}
                        expanded={expanded}
                        index={i}
                        item={item}
                      />
                    );
                  })}
              </>
            )}
          </>
        )}
      </Container>
    </Box>
  );
}

const SoldAccordian = ({ item, index, expanded, handleChange }) => {
  const textPrimary = {
    color: "rgba(255, 255, 255, 0.75)",
    fontFamily: "Gilroy-Medium",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  };
  const textSecondary = {
    color: "rgba(255, 255, 255, 0.75)",
    fontFamily: "Gilroy-Medium",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  };
  const text = {
    color: "#FFF",

    fontFamily: "Gilroy-Medium",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "15px",
    paddingTop: "7px",
  };
  const navigate = useNavigate();
  return (
    <Box>
      <Accordion
        key={`transaction${index}`}
        sx={{ background: "#233042", border: "none", margin: "10px 0px" }}
        expanded={expanded === index}
        onChange={handleChange(index)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
          aria-controls={item}
          id={item}
          sx={{
            "& .MuiAccordionSummary-content": {
              display: "flex",
              justifyContent: "space-between",
            },
          }}
        >
          <Box>
            <Typography sx={textPrimary} variant="subTitle1">
              Token Id:
            </Typography>
          </Box>
          <Box>
            <Typography sx={textPrimary} variant="subTitle1">
              {item?.tokenSymbol}
            </Typography>
          </Box>
        </AccordionSummary>
        <CommonDivider />
        <AccordionDetails>
          <Box mb={1} className="dispalySpacebetween">
            <Box>
              <Typography sx={textSecondary} className="ttt" variant="h6">
                Price
              </Typography>
              <Typography sx={text} className="ttt" variant="h6">
                {" "}
                {item?.tokenAmount}
              </Typography>
              <Typography
                style={{ paddingTop: "7px" }}
                sx={textSecondary}
                className="ttt"
                variant="h6"
              >
                Token Contract Address :
              </Typography>
              <Typography sx={text} className="ttt" variant="h6">
                {" "}
                {item && sortAddressTrans(item?.tokenAddress)}
                <CopyToClipboard text={item && item?.tokenAddress}>
                  <IconButton
                    style={{ height: "25px", width: "25px" }}
                    onClick={() => toast.success("Copied")}
                  >
                    <img src="images/copy-icon.svg" alt="" />
                  </IconButton>
                </CopyToClipboard>
              </Typography>
            </Box>
          </Box>
          <Box display="flex">
            <Button
              onClick={() => navigate("/alpha-scan")}
              fullWidth
              style={{
                borderRadius: "9.207px",
                border: "1px solid var(--Linear, #69BFFE)",
              }}
            >
              Aplbha
            </Button>
            &nbsp;
            <Button
              fullWidth
              style={{
                borderRadius: "9.207px",
                border: "1px solid var(--Linear, #69BFFE)",
              }}
              onClick={() => navigate("/chart")}
            >
              Chart
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
