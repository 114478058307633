import BackButton from "src/Components/commonbackbutton/BackButton";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/system";
import axios from "axios";
import ButtonCircular from "src/Components/ButtonCircular";
import { apiRouterCall } from "src/config/service";
import ApiConfig from "src/config/APICongig";
import { toast } from "react-toastify";

const EducationalBox = styled(Box)(({ theme }) => ({
  "& p": {
    wordBreak: "break-word",
    "@media(max-width:669px)": {
      fontSize: "12px !important",
    },
  },
}));

export default function EducationalContent() {
  const location = useLocation();
  const [type, setType] = useState("");
  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [dnsData, setDnsData] = useState("");
  useEffect(() => {
    const dataType = location?.state?.type;
    if (dataType) {
      setType(dataType);
    }
  }, [location]);

  const getEducationalContent = async () => {
    setIsLoading(true);
    try {
      const res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.cryptoEducation,
        bodyData: {
          topic: userName,
        },
      });
      console.log("cryptoEducation===", res);
      if (res.data.statusCode === 200) {
        setResponseData(res.data.responseResult);
        setUserName("");
      } else {
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <EducationalBox>
      <BackButton
        title={
          type === "education"
            ? "Educational content"
            : type === "dnsTwitter"
            ? "Twitter Handle from DNS"
            : "Twitter"
        }
      />
      <Container>
        {type === "education" && (
          <Box>
            <Typography variant="body1">Topic Name</Typography>
            <TextField
              placeholder="Enter the topic name"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              fullWidth
            />

            <Box mt={3}>
              <Button
                disabled={isLoading}
                onClick={() => getEducationalContent()}
                variant="contained"
              >
                Submit {isLoading && <ButtonCircular />}
              </Button>
            </Box>

            {responseData && (
              <Box mt={3} className="card_common">
                <Box p={2}>
                  <Typography variant="h6">
                    Educational Content Detail
                  </Typography>
                  <Box mt={2}>
                    {responseData.split("\n").map((content, index) => (
                      <Typography key={index} variant="body1" paragraph>
                        {content.split(" ").map((word, index) => (
                          <React.Fragment key={index}>
                            {word.startsWith("http") ? (
                              <a
                                href={word}
                                style={{
                                  color: "#3CADFF",
                                  marginRight: "5px",
                                  textDecoration: "underline",
                                  wordBreak: "break-word",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {word}
                              </a>
                            ) : (
                              <span>{word} </span>
                            )}
                          </React.Fragment>
                        ))}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Container>
    </EducationalBox>
  );
}
