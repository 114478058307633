import { Box } from "@mui/material";
import React from "react";
import BackButton from "./commonbackbutton/BackButton";

export default function Chart() {
  return (
    <Box>
      <BackButton title="Chart" />

      <iframe
        id="dextools-widget"
        title="DEXTools Trading Chart"
        width="100%"
        height="520px"
        src="https://www.dextools.io/widget-chart/en/ether/pe-light/0xa29fe6ef9592b5d408cca961d0fb9b1faf497d6d?theme=light&chartType=2&chartResolution=30&drawingToolbars=false"
      />
    </Box>
  );
}
