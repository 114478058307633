import BackButton from "src/Components/commonbackbutton/BackButton";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/system";
import axios from "axios";
import ButtonCircular from "src/Components/ButtonCircular";
import { apiRouterCall } from "src/config/service";
import ApiConfig from "src/config/APICongig";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import { sortAddressTrans } from "src/utils";

const TokenDetailBox = styled(Box)(({ theme }) => ({
  "& p": {
    wordBreak: "break-word",
    "@media(max-width:669px)": {
      fontSize: "12px !important",
    },
  },
}));
const priceArray = ["Select Price", "change", "highest_price", "sale"];
const MintPriceArray = [
  "mint_num",
  "mint_volume",
  "minter_num",
  "whale_num",
  "total_gas_fee",
  "first_mint_time",
  "fomo",
];

const categoryArray = [
  "ALL",
  "DEFI",
  "METAVERSE",
  "COLLECTIBLES",
  "ART",
  "UTILITY",
  "SOCIAL",
  "MUSIC",
  "PFP",
  "LAND",
  "PHOTOGRAPHY",
  "SPORTS",
  "DOMAIN_NAMES",
];

export default function NftMetrics() {
  const location = useLocation();
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [contractAddress, setContractAddress] = useState("");

  const [tokenId, setTokenId] = useState("");
  const [topNftData, setTopNftData] = useState("");
  const [nftMintData, setNftMintData] = useState("");
  const [nftChannelData, setNftChannelData] = useState("");
  const [portFolioMetrics, setPortFolioMetrics] = useState("");
  const [rankingData, setRankingData] = useState("");

  const [coinDetails, setCoinDetails] = useState({
    time: "topNftSales",
    price: "price",
    category: "ALL",
  });
  useEffect(() => {
    const dataType = location?.state?.type;
    if (dataType) {
      setType(dataType);
    }
  }, [location]);
  const [isSubmit, setIsSubmit] = useState(false);

  const topNftHandler = async () => {
    setIsSubmit(true);
    if (
      coinDetails.time !== "topNftSales" &&
      coinDetails.price !== "price" &&
      coinDetails.category !== "ALL"
    ) {
      setIsLoading(true);
      try {
        const res = await apiRouterCall({
          method: "POST",
          url: ApiConfig.summonTopNFTBySales,
          bodyData: {
            time: coinDetails.time,
            price: coinDetails.price,
            category: coinDetails.category,
          },
        });
        console.log("resooooo===", res);
        if (res.data.statusCode === 200) {
          setTopNftData(res.data.responseResult);
          // toast.success(res.data.responseMessage);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.responseMessage);
          setTopNftData([]);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setTopNftData([]);
      }
    }
  };
  const TopNFTByMintApiHandler = async () => {
    setIsSubmit(true);
    if (
      coinDetails.time !== "topNftSales" &&
      coinDetails.price !== "price" &&
      coinDetails.category !== ""
    ) {
      setIsLoading(true);
      try {
        const res = await apiRouterCall({
          method: "POST",
          url: ApiConfig.evokeTopNFTByMint,
          bodyData: {
            time: coinDetails.time,
            price: coinDetails.price,
          },
        });
        console.log("resooooo===", res);
        if (res.data.statusCode === 200) {
          setNftMintData(res.data.responseResult);
          // toast.success(res.data.responseMessage);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.responseMessage);
          setNftMintData([]);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setNftMintData([]);
      }
    }
  };
  const channelNftMetricsHandler = async () => {
    setIsSubmit(true);
    if (contractAddress !== "" && tokenId !== "") {
      setIsLoading(true);
      try {
        const res = await apiRouterCall({
          method: "POST",
          url: ApiConfig.channelNFTMetrices,
          bodyData: {
            nftAddress: contractAddress,
            tokenID: tokenId,
          },
        });
        console.log("resooooo===", res);
        if (res.data.statusCode === 200) {
          setNftChannelData(res.data.responseResult);
          toast.success(res.data.responseMessage);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };
  const portFolioMetricesHandler = async () => {
    setIsSubmit(true);
    if (contractAddress !== "") {
      setIsLoading(true);
      try {
        const res = await apiRouterCall({
          method: "POST",
          url: ApiConfig.portFolioMetrices,
          bodyData: {
            userAddress: contractAddress,
          },
        });
        console.log("resooooo===", res);

        if (res.data.statusCode === 200) {
          const portfolioData = res.data.responseResult;

          // Update state with portfolio data
          setPortFolioMetrics({
            portfolioHistory1h: {
              eth: portfolioData?.portfolioHistory1h?.eth,
              usd: portfolioData?.portfolioHistory1h?.usd,
            },
            portfolioValue: {
              eth: portfolioData?.portfolioValue?.eth,
              usd: portfolioData?.portfolioValue?.usd,
            },
          });

          // toast.success(res.data.responseMessage);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.responseMessage);
          setPortFolioMetrics({});
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setPortFolioMetrics({});
      }
    }
  };
  const topNFTByMarketRankingHandler = async () => {
    setIsLoading(true);
    try {
      const res = await apiRouterCall({
        method: "GET",
        url: ApiConfig.topNFTByMarketRanking,
      });
      console.log("resooooo===", res);
      if (res.data.statusCode === 200) {
        setRankingData(res.data.responseResult);

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (type === "NFTMarketRanking") {
      topNFTByMarketRankingHandler();
    }
  }, [type]);

  return (
    <TokenDetailBox>
      <BackButton
        title={
          type === "channelNftMetrics"
            ? "Channel NFT Metrics"
            : type === "topNftSales"
              ? "Top NFTs by Sales"
              : type === "topNftMint"
                ? "Evoke Top NFTs by Mint"
                : type === "NFTMarketRanking"
                  ? "NFT Market Ranking"
                  : type === "PortfolioMetrics"
                    ? "Portfolio Metrics"
                    : ""
        }
      />
      <Container>
        {type === "NFTMarketRanking" && (
          <Box mt={4} mb={2}>
            <Box>
              {isLoading && type === "NFTMarketRanking" ? (
                <Box display="flex" justifyContent="center" mt={1} mb={1}>
                  <ButtonCircular />
                </Box>
              ) : (
                <>
                  {rankingData && (
                    <Box className="card_common">
                      <Box p={2}>
                        <Typography variant="h6">
                          NFT Market Ranking Detail
                        </Typography>
                        <Box mt={1}>
                          {rankingData &&
                            rankingData.map((nftMarket, index) => (
                              <Box
                                key={index}
                                mt={2}
                                className="your-custom-class"
                              >
                                <Typography variant="body1">
                                  Address:{" "}
                                  {nftMarket &&
                                    nftMarket.address &&
                                    sortAddressTrans(nftMarket.address)}
                                  {nftMarket && nftMarket.address && (
                                    <CopyToClipboard
                                      text={nftMarket && nftMarket.address}
                                    >
                                      <IconButton
                                        style={{
                                          height: "20px",
                                          width: "20px",
                                        }}
                                        onClick={() => toast.success("Copied")}
                                      >
                                        <img
                                          src="images/copy-icon.svg"
                                          alt=""
                                          style={{ height: "14px" }}
                                        />
                                      </IconButton>
                                    </CopyToClipboard>
                                  )}
                                </Typography>
                                <Typography variant="body1">
                                  Blockchain: {nftMarket.blockchain}
                                </Typography>
                                <Typography variant="body1">
                                  Is Blue Chip Holder:{" "}
                                  {nftMarket.isBlueChipHolder ? "Yes" : "No"}
                                </Typography>
                                <Typography variant="body1">
                                  PNL (ETH): {nftMarket.pnlEth}
                                </Typography>
                                <Typography variant="body1">
                                  PNL (USD): {nftMarket.pnlUSD}
                                </Typography>
                              </Box>
                            ))}
                          {rankingData && rankingData.length === 0 && (
                            <Typography textAlign="center" variant="h6">
                              Data Not Found
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        )}
        {type === "channelNftMetrics" && (
          <Box mt={4} mb={2}>
            <TextField
              placeholder="Enter contract address"
              type="text"
              variant="outlined"
              fullWidth
              value={contractAddress}
              onChange={(e) => setContractAddress(e.target.value)}
              error={isSubmit && contractAddress === ""}
              helperText={
                isSubmit &&
                contractAddress === "" &&
                "Please enter contract address"
              }
            />
            <Box mt={2} mb={2}>
              <TextField
                type="text"
                variant="outlined"
                fullWidth
                placeholder="Enter token ID"
                value={tokenId}
                onChange={(e) => setTokenId(e.target.value)}
                error={isSubmit && tokenId === ""}
                helperText={
                  isSubmit && tokenId === "" && "Please enter contract address"
                }
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={channelNftMetricsHandler}
              disabled={isLoading}
            >
              Submit {isLoading && <ButtonCircular />}
            </Button>
            <Box>
              {nftChannelData && typeof nftChannelData === "object" && (
                <Box mt={3} className="card_common">
                  <Box p={2}>
                    <Typography variant="h6">
                      Channel NFTs Metrics Detail
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="body1">
                        Name: {nftChannelData?.name}
                      </Typography>
                      <Typography variant="body1">
                        Description: {nftChannelData?.description}
                      </Typography>
                      <Typography variant="body1">
                        Blockchain: {nftChannelData?.blockchain}
                      </Typography>
                      <Typography variant="body1">
                        Last Sale Price:{" "}
                        {nftChannelData?.last_sale?.price?.value}{" "}
                        {nftChannelData?.last_sale?.price?.usd}
                      </Typography>
                      <Typography variant="body1">
                        Last Sale Time:{" "}
                        {new Date(
                          nftChannelData?.last_sale?.time * 1000
                        ).toLocaleString()}
                      </Typography>
                      <Typography variant="body1">
                        Owner Address: {nftChannelData?.owner_addresses[0]}
                      </Typography>
                      <Typography variant="body1">
                        Token ID: {nftChannelData?.token_id}
                      </Typography>
                      <Typography variant="body1">
                        Traits: {nftChannelData?.traits?.join(", ")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}
        {type === "PortfolioMetrics" && (
          <Box mt={4} mb={2}>
            <TextField
              placeholder="Enter user address"
              type="text"
              variant="outlined"
              fullWidth
              value={contractAddress}
              onChange={(e) => setContractAddress(e.target.value)}
              error={isSubmit && contractAddress === ""}
              helperText={
                isSubmit &&
                contractAddress === "" &&
                "Please enter user address"
              }
            />

            <Box mt={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={portFolioMetricesHandler}
                disabled={isLoading}
              >
                Submit {isLoading && <ButtonCircular />}
              </Button>
            </Box>
            <Box>
              {portFolioMetrics && (
                <Box mt={3} className="card_common">
                  <Box p={2}>
                    {portFolioMetrics?.portfolioHistory1h?.eth ||
                      portFolioMetrics?.portfolioHistory1h?.usd ||
                      portFolioMetrics?.portfolioValue?.eth ||
                      portFolioMetrics?.portfolioValue?.usd ? (
                      <>
                        <Typography variant="h6">
                          Portfolio Metrics Detail
                        </Typography>
                        <Box mt={1}>
                          <Typography variant="body1">
                            Portfolio History 1h (ETH):{" "}
                            {portFolioMetrics.portfolioHistory1h.eth
                              ? portFolioMetrics.portfolioHistory1h.eth
                              : "N/A"}
                          </Typography>
                          <Typography variant="body1">
                            Portfolio History 1h (USD):{" "}
                            {portFolioMetrics.portfolioHistory1h.usd
                              ? portFolioMetrics.portfolioHistory1h.usd
                              : "N/A"}
                          </Typography>
                          <Typography variant="body1">
                            Portfolio Value (ETH):{" "}
                            {portFolioMetrics.portfolioValue.eth
                              ? portFolioMetrics.portfolioValue.eth
                              : "N/A"}
                          </Typography>
                          <Typography variant="body1">
                            Portfolio Value (USD):{" "}
                            {portFolioMetrics.portfolioValue.usd
                              ? portFolioMetrics.portfolioValue.usd
                              : "N/A"}
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      <Typography textAlign="center" variant="h6">
                        No Data is shown
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}
        {type === "topNftSales" && (
          <Box mt={4} mb={2}>
            <Typography>Time</Typography>

            <FormControl variant="outlined" fullWidth>
              <Select
                value={coinDetails.time}
                onChange={(e) =>
                  setCoinDetails({
                    ...coinDetails,
                    ["time"]: e.target.value,
                  })
                }
                fullWidth
                error={
                  (isSubmit && coinDetails?.time === "") ||
                  (isSubmit && coinDetails?.time === "topNftSales")
                }
              >
                {" "}
                <MenuItem disabled value="topNftSales">
                  Select Time
                </MenuItem>
                <MenuItem value="24h">24h</MenuItem>
                <MenuItem value="7d">7d</MenuItem>
                <MenuItem value="30d">30d</MenuItem>
                <MenuItem value="3M">3M</MenuItem>
                <MenuItem value="1y">1y</MenuItem>
                <MenuItem value="all">all</MenuItem>
              </Select>
              {(isSubmit && coinDetails?.time === "") ||
                (isSubmit && coinDetails?.time === "topNftSales" && (
                  <FormHelperText error>Please select time.</FormHelperText>
                ))}
            </FormControl>
            <Box mt={2} mb={2}>
              <Typography>Price</Typography>
              <FormControl variant="outlined" fullWidth>
                <Select
                  value={coinDetails.price}
                  onChange={(e) =>
                    setCoinDetails({
                      ...coinDetails,
                      ["price"]: e.target.value,
                    })
                  }
                  fullWidth
                  error={
                    (isSubmit && coinDetails?.price === "") ||
                    (isSubmit && coinDetails?.price === "price")
                  }
                >
                  {priceArray.map((priceOption, i) => (
                    <MenuItem
                      disabled={i === 0}
                      key={priceOption}
                      value={priceOption}
                    >
                      {priceOption}
                    </MenuItem>
                  ))}
                </Select>
                {(isSubmit && coinDetails?.price === "") ||
                  (isSubmit && coinDetails?.price === "price" && (
                    <FormHelperText error>Please select price.</FormHelperText>
                  ))}
              </FormControl>
            </Box>
            <Box mt={2} mb={2}>
              <Typography>Category</Typography>
              <FormControl variant="outlined" fullWidth>
                <Select
                  value={coinDetails.category}
                  onChange={(e) =>
                    setCoinDetails({
                      ...coinDetails,
                      ["category"]: e.target.value,
                    })
                  }
                  fullWidth
                  error={
                    (isSubmit && coinDetails?.category === "") ||
                    (isSubmit && coinDetails?.category === "ALL")
                  }
                >
                  {categoryArray.map((categoryOption, i) => (
                    <MenuItem
                      disabled={i === 0}
                      key={categoryOption}
                      value={categoryOption}
                    >
                      {categoryOption}
                    </MenuItem>
                  ))}
                </Select>
                {(isSubmit && coinDetails?.category === "") ||
                  (isSubmit && coinDetails?.category === "ALL" && (
                    <FormHelperText error>
                      Please select category.
                    </FormHelperText>
                  ))}
              </FormControl>
            </Box>
            <Button variant="contained" color="primary" onClick={topNftHandler}>
              Submit {isLoading && <ButtonCircular />}
            </Button>
            <Box>
              {topNftData && topNftData?.length > 0 && (
                <Box mt={3}>
                  <Box>
                    <Typography variant="h6">
                      Top NFTs by Sales Detail
                    </Typography>
                    <Box mt={1}>
                      {topNftData &&
                        topNftData.map((nft, index) => (
                          <Box
                            key={index}
                            mt={2}
                            className="your-custom-class card_common"
                            style={{
                              border: "1px  solid rgba(255, 255, 255, 0.05)",
                              borderRadius: "10px",
                              padding: "10px",
                            }}
                          >
                            <Typography variant="h6">{nft?.name}</Typography>
                            <Box mt={1}>
                              <img
                                src={nft?.image}
                                alt={nft?.name}
                                width="100%"
                                style={{ borderRadius: "10px" }}
                              />

                              <Typography variant="body1">
                                Last Price: {nft?.lastPrice?.value}{" "}
                                {nft?.lastPrice?.cryptoUnit}
                              </Typography>
                              <Typography variant="body1">
                                Max Price Prediction:{" "}
                                {nft?.maxPricePrediction?.value}{" "}
                                {nft?.maxPricePrediction?.cryptoUnit}
                              </Typography>
                              <Typography variant="body1">
                                Number of Sales:{" "}
                                {nft?.salesDetails?.numberOfSales}
                              </Typography>

                              <Typography variant="body1">
                                Rarity Score: {nft?.rarity?.score}
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                </Box>
              )}
              {topNftData && topNftData.length === 0 && (
                <Typography pt={4} textAlign="center" variant="h6">
                  Data Not Found
                </Typography>
              )}
            </Box>
          </Box>
        )}
        {type === "topNftMint" && (
          <Box mt={4} mb={2}>
            <Typography>Time</Typography>

            <FormControl variant="outlined" fullWidth>
              <Select
                value={coinDetails.time}
                onChange={(e) =>
                  setCoinDetails({
                    ...coinDetails,
                    ["time"]: e.target.value,
                  })
                }
                fullWidth
                error={
                  (isSubmit && coinDetails?.time === "") ||
                  (isSubmit && coinDetails?.time === "topNftSales")
                }
              >
                {" "}
                <MenuItem value="topNftSales" disabled>
                  Select Time
                </MenuItem>
                <MenuItem value="5m">5m</MenuItem>
                <MenuItem value="10m">10m</MenuItem>
                <MenuItem value="30d">30d</MenuItem>
                <MenuItem value="30m">30m</MenuItem>
                <MenuItem value="6h">6h</MenuItem>
                <MenuItem value="12h">12h</MenuItem>
                <MenuItem value="24h">24h</MenuItem>
              </Select>
              {(isSubmit && coinDetails?.time === "") ||
                (isSubmit && coinDetails?.time === "topNftSales" && (
                  <FormHelperText error>Please select time.</FormHelperText>
                ))}
            </FormControl>
            <Box mt={2} mb={2}>
              <Typography>Price</Typography>
              <FormControl variant="outlined" fullWidth>
                <Select
                  value={coinDetails.price}
                  onChange={(e) =>
                    setCoinDetails({
                      ...coinDetails,
                      ["price"]: e.target.value,
                    })
                  }
                  fullWidth
                  error={
                    (isSubmit && coinDetails?.price === "") ||
                    (isSubmit && coinDetails?.price === "price")
                  }
                >
                  {MintPriceArray.map((priceOption, i) => (
                    <MenuItem key={priceOption} value={priceOption}>
                      {priceOption}
                    </MenuItem>
                  ))}
                </Select>
                {(isSubmit && coinDetails?.price === "") ||
                  (isSubmit && coinDetails?.price === "price" && (
                    <FormHelperText error>Please select price.</FormHelperText>
                  ))}
              </FormControl>
            </Box>

            <Button
              variant="contained"
              color="primary"
              onClick={TopNFTByMintApiHandler}
            >
              Submit {isLoading && <ButtonCircular />}
            </Button>
            <Box>
              {nftMintData && nftMintData?.length > 0 && (
                <Box mt={3} className="card_common">
                  <Box p={2}>
                    <Typography variant="h6">Top NFTs Mint Detail</Typography>
                    <Box mt={1}>
                      {nftMintData.map((nft, index) => (
                        <Box key={index} mt={2} className="your-custom-class">
                          <Typography variant="body1">
                            {nft.collectionDetails.name}
                          </Typography>

                          <Box mt={1}>
                            <Typography variant="body1">
                              Mint Volume:{" "}
                              {nft.basicDetails.mintVolume.usdPrice}{" "}
                            </Typography>

                            <Typography variant="body1">
                              Blockchain Used: {nft.chainDetails.blockchainUsed}
                            </Typography>
                            <Typography variant="body1">
                              First Mint Time:{" "}
                              {new Date(
                                nft.chainDetails.firstMintTime
                              ).toLocaleString()}
                            </Typography>
                            <Typography variant="body1">
                              FOMO: {nft.chainDetails.fomo}
                            </Typography>
                            <Typography variant="body1">
                              Minter Number: {nft.chainDetails.minterNumber}
                            </Typography>
                            <Typography variant="body1">
                              Units Minted: {nft.chainDetails.unitsMinted}
                            </Typography>
                            <Typography variant="body1">
                              Whale Number: {nft.chainDetails.whaleNumber}
                            </Typography>
                            <Typography variant="body1">
                              Contract URL:{" "}
                              <a
                                href={nft.collectionDetails.contractURL}
                                target="_blank"
                                style={{ color: "rgba(105, 191, 254, 1)" }}
                                rel="noopener noreferrer"
                              >
                                {nft.collectionDetails.contractURL}
                              </a>
                            </Typography>
                            <Typography variant="body1">
                              Gas Fee: USD {nft.gasDetails.usd.value}{" "}
                              {nft.gasDetails.usd.cryptoUnit}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              )}
              {nftMintData && nftMintData.length === 0 && (
                <Typography pt={4} textAlign="center" variant="h6">
                  Data Not Found
                </Typography>
              )}
            </Box>
          </Box>
        )}
      </Container>
    </TokenDetailBox>
  );
}
