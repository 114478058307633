// const Nodeurl = "http://172.16.2.11:2038"; //nikhil
// const Nodeurl = "http://172.16.1.224:2038"; //rangoli
const Nodeurl = "https://node-unibotwebapp.mobiloitte.io";
const url = "https://py-telegramunitbot.mobiloitte.io";

export const baseUrl = "https://py-telegramunitbot.mobiloitte.io/";

// export const nodeUrl = "ws://172.16.1.224:2038";
export const nodeUrl = "https://node-unibotwebapp.mobiloitte.io";
let Nodebot = `${Nodeurl}/api/v1/bot`;
let user = `${Nodeurl}/api/v1/user`;

const ApiConfig = {
  // python api

  CheckChatId: `${url}/CheckChatId`,
  createChatId: `${url}/createChatId`,
  activeWallet: `${url}/activeWallet`,
  setWallet: `${url}/setWallet`,
  stop: `${url}/stop`,
  mirror: `${url}/mirror`,
  start: `${url}/start`,
  setMaxMirrorLimit: `${url}/setMaxMirrorLimit`,
  setMirrorWalletPython: `${url}/setMirrorWallet`,
  mirrorStop: `${url}/mirrorStop`,

  currentWallet: `${url}/currentWallet`,
  getEthBalance: `${url}/getEthBalance`, //eth buy to check balance
  getCurrentTokenHolding: `${url}/getCurrentTokenHolding`,

  walletToDeposit: `${url}/walletToDeposit`,
  getLiquidationThreshold: `${url}/getLiquidationThreshold`,
  getCurrentLeverage: `${url}/getCurrentLeverage`, //leverageto attain
  getHealthFactor: `${url}/getHealthFactor`, //trustScore
  getLeverage: `${url}/getLeverage`,
  startWithLeverage: `${url}/startWithLeverage`,
  stopLeverageSniping: `${url}/stopLeverageSniping`,
  godModeStart: `${url}/godModeStart`,
  getTokenSold: `${url}/getTokenSold`,
  makeContributions: `${url}/makeContributions`,
  buytokenswithtokens: `${url}/buytokenswithtokens`, //buy token
  getTokenInfo: `${url}/getTokenInfo/eth`,
  // node api
  fetchNewsAndStore: `${Nodebot}/fetchNewsAndStore`,
  getEnsDomain: `${Nodebot}/getEnsDomain`,
  getTwitterHandle: `${Nodebot}/getTwitterHandle`,
  unveilTokenPower: `${Nodebot}/unveilTokenPower`,
  cryptoEducation: `${Nodebot}/cryptoEducation`,
  summonTopNFTBySales: `${Nodebot}/summonTopNFTBySales`,
  evokeTopNFTByMint: `${Nodebot}/evokeTopNFTByMint`,
  channelNFTMetrices: `${Nodebot}/channelNFTMetrices`,
  portFolioMetrices: `${Nodebot}/portFolioMetrices`,
  topNFTByMarketRanking: `${Nodebot}/topNFTByMarketRanking`,
  coinList: `${user}/coinList`,
  setPrice: `${user}/setPrice`,
  connectWallet: `${user}/connectWallet`,
  getProfile: `${user}/getProfile`,
  startMempool: `${Nodebot}/startMempool`,
  startStopTransaction: `${Nodebot}/startStopTransaction`,
  stopMempool: `${Nodebot}/stopMempool`,
  buyToken: `${Nodebot}/buyToken`,
  sellToken: `${Nodebot}/sellToken`,
  createPoll: `${Nodebot}/createPoll`,
  getPoll: `${Nodebot}/getPolls`,
  votePoll: `${Nodebot}/votePoll`,
  createQuiz: `${Nodebot}/createQuiz`,
  getQuiz: `${Nodebot}/getQuiz`,
  voteQuiz: `${Nodebot}/voteQuiz`,
  generateAndStore: `${Nodebot}/generateAndStore`,
  getTokenBalance: `${Nodebot}/getTokenBalance`,
  approveTransaction: `${Nodebot}/approveTransaction`,
  checkAllowance: `${Nodebot}/checkAllowance`,
  deletePoll: `${Nodebot}/deletePoll`,
  deleteQuiz: `${Nodebot}/deleteQuiz`,

  getMirrorWallet: `${user}/getMirrorWallet`,
  setMirrorWallet: `${user}/setMirrorWallet`,
  deletePriceAlert: `${user}/deletePriceAlert`,
  viewPriceAlert: `${user}/viewPriceAlert`,
};

export default ApiConfig;
