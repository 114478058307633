import CommonCard from "src/Components/CommonCard";
import React from "react";
import SnipingCard from "src/Components/leverageSniping/SnipingCard";
import Deposite from "src/Components/Deposite";
import ProfitAttain from "src/Components/ProfitAttain";
import TokenBought from "src/pages/tokenbought/TokenBought";
import Chart from "src/Components/Chart";
import AlphaScan from "src/pages/alphascan/AlphaScan";
import TransactionDive from "src/pages/transactiondive";
import StakingFarming from "src/pages/stakingfarming";
import NftMetrics from "src/pages/nftmetrics";
import PriceAlert from "src/pages/pricealert";
import TwitterDns from "src/pages/twitter/TwitterDns";
import EducationalContent from "src/pages/education";
import MemePoll from "src/pages/memepoll/MemePoll";
import PollQuiz from "src/pages/pollquiz/PollQuiz";

const HomePage = React.lazy(() => import("../pages/Home"));
const BuySection = React.lazy(() => import("../Components/buyModal/BuyModal"));
const GodMode = React.lazy(() =>
  import("src/Components/currentwallet/GodMode")
);
const FundHeld = React.lazy(() =>
  import("src/Components/currentwallet/FundHeld")
);
const TransactionHistory = React.lazy(() =>
  import("src/Components/currentwallet/TransactionHistory")
);
const CurrentWallet = React.lazy(() =>
  import("src/Components/currentwallet/CurrentWallet")
);

const routes = [
  {
    path: "/",
    element: HomePage,
  },
  {
    path: "/wallet",
    element: CommonCard,
  },
  {
    path: "/buy-token",
    element: BuySection,
  },
  {
    path: "/twitter-dns",
    element: TwitterDns,
  },
  {
    path: "/education-content",
    element: EducationalContent,
  },
  {
    path: "/current-wallet",
    element: CurrentWallet,
  },
  {
    path: "/transaction-history",
    element: TransactionHistory,
  },
  {
    path: "/fund-held",
    element: FundHeld,
  },
  {
    path: "/god-mode",
    element: GodMode,
  },
  {
    path: "/deposite",
    element: CurrentWallet,
  },
  {
    path: "/make-contribution",
    element: Deposite,
  },
  {
    path: "/profit-attain",
    element: ProfitAttain,
  },
  {
    path: "/token-bought",
    element: TokenBought,
  },
  {
    path: "/Chart",
    element: Chart,
  },
  {
    path: "/alpha-scan",
    element: AlphaScan,
  },
  {
    path: "/dive",
    element: TransactionDive,
  },
  {
    path: "/news",
    element: TransactionDive,
  },
  {
    path: "/farming",
    element: StakingFarming,
  },
  {
    path: "/nft-metrics",
    element: NftMetrics,
  },
  {
    path: "/price-alert",
    element: PriceAlert,
  },
  {
    path: "/memepoll",
    element: MemePoll,
  },
  {
    path: "/poll-quiz",
    element: PollQuiz,
  },

  // {
  //   path: "/404",
  //   element: PageNotFound,
  //   // layout: HomeLayout,
  // },
];
export default routes;
