import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import ButtonCircular from "./ButtonCircular";

export default function StartStopModal({
  open,
  setOpen,
  type,
  funCall,
  isLoading,
}) {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Box style={{ position: "absolute", right: "0px", top: "0px" }}>
        <IconButton onClick={() => setOpen(false)}>
          <Close style={{ color: "#fff" }} />
        </IconButton>
      </Box>
      <DialogContent>
        <Box>
          <Typography textAlign="center" variant="h5" style={{ color: "#fff" }}>
            Are you sure want to {type}?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isLoading && isLoading}
          style={{ width: "100px" }}
          variant="contained"
          onClick={funCall}
        >
          Yes {isLoading && <ButtonCircular />}
        </Button>
        <Button
          style={{ width: "100px" }}
          onClick={() => setOpen(false)}
          variant="contained"
          disabled={isLoading && isLoading}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
